export default class ReportAPI {
  constructor(args) {
    this.request = args.request
  }

  downloadMusicianPaysheet = (queryStr, fileName) =>
    this.request.downloadCsv(`/Reports/MusicianPay?${queryStr}`, fileName)

  getBookedMusicians = params =>
    this.request.get('/Reports/EventGigSummary', { params })

  downloadAirWallexPayments = params =>
    this.request.get('/Reports/Payments/Export', { params })

  downloadOutstandingPayments = params =>
    this.request.get('/Reports/Payments/Outstanding/Export', { params })
}
