import { notification } from 'antd'
import get from 'lodash/get'
import { action, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'

import resettableMixin from '@/flynk.app.web.core.data/stores/resettableMixin'

@resettableMixin
class PerformerStore {
  @persist('list') @observable performerSkills = []

  @observable getPerformerSkillsLoading = false

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @action.bound
  async getPerformerSkills() {
    this.getPerformerSkillsLoading = true

    try {
      const res = await this.rootAPI.performerAPI.getPerformerSkills()
      this.performerSkills = get(res, 'payload', [])
      // this.setPagination({
      //   ...this.pagination,
      //   current: res.payload.page,
      //   pageSize: res.payload.pageSize,
      //   total: res.payload.totalItems,
      // })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    this.getPerformerSkillsLoading = false
  }

  @persist('list') @observable performers = []

  @observable getPerformersLoading = false

  @action.bound
  async getPerformers() {
    this.getPerformersLoading = true
    let res = null

    try {
      res = await this.rootAPI.performerAPI.getPerformers(
        this.rootStore.cityStore.selectedCityId
      )

      runInAction(() => {
        // Sort by alphabet
        this.performers = res?.payload?.sort((a, b) =>
          a?.subject?.fullname?.localeCompare(b?.subject?.fullname)
        )
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformersLoading = false
    }

    return res
  }

  @observable updatePerformerLoading = false

  @action.bound
  async updatePerformer(data) {
    this.updatePerformerLoading = true

    try {
      return await this.rootAPI.performerAPI.updatePerformer({
        ...data,
        cityId: this.rootStore.cityStore.selectedCityId,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updatePerformerLoading = false
    }
  }

  @action.bound
  async onPerformerUpdateComplete(res) {
    if (!res?.payload) return

    notification.success({
      message: 'Success!',
      description: 'Update Performer Successfully',
      placement: 'bottomRight',
    })
    await this.getPerformers()
  }

  @observable updatePositionPayrateLoading = false

  @action.bound
  async updatePositionPayrate(positionId, data) {
    if (!positionId) {
      return
    }

    this.updatePositionPayrateLoading = true

    try {
      await this.rootAPI.performanceAPI.updatePositionPayrate(positionId, data)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updatePositionPayrateLoading = false
    }
  }

  // setPagination = (pagination) => {
  //   this.pagination = pagination
  // }
}

export default PerformerStore
