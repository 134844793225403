import {
  DEFAULT_PAGINATION,
  SetDefaultPagination,
  USER_STATUSES,
} from 'flynk.app.web.core.data/constants'
import resettableMixin from 'flynk.app.web.core.data/stores/resettableMixin'
import set from 'lodash/set'
import { action, computed, observable } from 'mobx'

import { INITIAL_MODAL_DATA } from '@/constants'
import { TaskStatus } from '@/constants/task'

@resettableMixin
class TaskStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @observable tasks = {
    pagination: DEFAULT_PAGINATION,
    items: [],
  }

  @observable myTasks = []

  @computed get unreadTaskQuantity() {
    return (this.myTasks || []).filter(task => !task.isRead)?.length
  }

  @observable getTasksLoading = false

  @observable taskFilter = {}

  @observable bandManagers = []

  @action.bound
  async getTasks(
    data = {
      ...this.taskFilter,
      skip: 0,
      take: this.tasks.pagination.pageSize,
    }
  ) {
    this.getTasksLoading = true
    const {
      bookingAdminStore: {
        booking: { id: bookingId },
      },
      cityStore: { selectedCityId },
    } = this.rootStore

    try {
      const res = await this.rootAPI.taskAPI.getTasks({
        bookingId,
        cityId: selectedCityId,
        ...data,
      })

      this.tasks = {
        items: res?.payload?.items,
        pagination: SetDefaultPagination(this.tasks.pagination, res),
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getTasksLoading = false
    }
  }

  @action.bound
  refreshTasks() {
    return Promise.all([this.getTasks(), this.getMyTasks()])
  }

  @observable taskActionLoading = false

  @action.bound
  async createTask(data) {
    this.taskActionLoading = true
    const {
      cityStore: { selectedCityId },
    } = this.rootStore

    try {
      return await this.rootAPI.taskAPI.createTask({
        cityId: selectedCityId,
        ...data,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.taskActionLoading = false
    }
  }

  @action.bound
  async updateTask(id, data) {
    this.taskActionLoading = true

    try {
      return await this.rootAPI.taskAPI.updateTask(id, data)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.taskActionLoading = false
    }
  }

  @action.bound
  async deleteTask(id) {
    this.taskActionLoading = true

    try {
      return await this.rootAPI.taskAPI.updateTask(id)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.taskActionLoading = false
    }
  }

  @observable getMyTasksLoading = false

  @action.bound
  async getMyTasks() {
    const { selectedCityId } = this.rootStore?.cityStore ?? {}
    this.getMyTasksLoading = true

    const {
      taskAPI: { getMyTasks },
    } = this.rootAPI

    const GET_MY_TASK_TIMEOUT = 1000 * 60 * 3 // 3 minutes

    const getMyTaskCallback = async () => {
      try {
        const res = await getMyTasks({ cityId: selectedCityId })
        this.myTasks = (res?.payload ?? []).filter(
          task => task?.status !== TaskStatus.Done
        )
      } catch (err) {
        this.rootStore.errorsStore.addError(err)
      }
    }

    if (this.getMyTaskInterval) {
      clearInterval(this.getMyTaskInterval)
    }

    try {
      await getMyTaskCallback()
      this.getMyTaskInterval = setInterval(
        getMyTaskCallback,
        GET_MY_TASK_TIMEOUT
      )
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getMyTasksLoading = false
    }
  }

  @observable taskModalData = INITIAL_MODAL_DATA

  @action.bound
  setTaskModalData(taskModalData) {
    this.taskModalData = taskModalData
  }

  @action.bound
  resetTaskModalData() {
    this.taskModalData = INITIAL_MODAL_DATA
  }

  @action.bound
  setTaskFilter(name, value) {
    set(this.taskFilter, name, value)
  }

  @action.bound
  updateTaskFromMyTasks(task) {
    this.myTasks = this.myTasks.map(myTask =>
      myTask?.id === task?.id ? task : myTask
    )
  }

  @observable getBandManagersByCityIdLoading = false

  @action.bound
  async getBandManagersByCityId(cityId) {
    this.getBandManagersByCityIdLoading = true

    if (!cityId) return

    const {
      rootAPI: {
        userAPI: { getSalesManagersByCityId },
      },
      rootStore: {
        errorsStore: { addError },
      },
    } = this

    try {
      const res = await getSalesManagersByCityId(cityId, USER_STATUSES.Enabled)

      if (res && res.payload) {
        this.bandManagers = res?.payload?.map(
          salesManager => salesManager?.user
        )
      }

      return this.bandManagers
    } catch (err) {
      addError(err)
    } finally {
      this.getBandManagersByCityIdLoading = false
    }
  }

  @observable taskDetail = null

  @action.bound
  setTaskDetail(taskDetail) {
    this.taskDetail = taskDetail
  }
}

export default TaskStore
