export const isProd = window.env
  ? window.env.REACT_APP_LOG_ENV === 'production'
  : false

export const isDev = window.env
  ? window.env.REACT_APP_LOG_ENV === 'development'
  : true

export const isTest = window.env
  ? window.env.REACT_APP_LOG_ENV === 'test'
  : false
