import { notification } from 'antd'
import { action, computed, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'

export default class VenuesStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @observable mapObject = {}

  @observable loading = false

  @observable getTravelChargesByCityIdLoading = false

  @observable venuesTravelCharges = []

  @observable currentVenue = {}

  @observable searchQuery = ''

  @persist('object') @observable cityIdsVenuesCache = {}

  @computed get selectedCityId() {
    return this.rootStore.cityStore.selectedCityId
  }

  @computed get selectedVenues() {
    return this.cityIdsVenuesCache[this.selectedCityId] || []
  }

  @action.bound
  updateSelectedVenues(venue) {
    const selectedVenueIndex = this.selectedVenues.findIndex(
      el => el?.place_id === venue?.placeId || el?.place_id === venue?.place_id
    )

    if (selectedVenueIndex !== -1) {
      this.cityIdsVenuesCache[this.selectedCityId].splice(selectedVenueIndex, 1)
      this.cityIdsVenuesCache[this.selectedCityId].unshift(venue)
    } else {
      this.cityIdsVenuesCache[this.selectedCityId] = [
        venue,
        ...this.selectedVenues,
      ]
    }
  }

  @action.bound
  selectedVenueByPlaceId = async venue => {
    const { payload } = await this.rootAPI.venuesAPI.getVenues(venue?.place_id)

    const venueDetails = {
      ...venue,
      ...payload,
      overrideTravelPayoutPerPerformer:
        payload.overrideTravelPayoutPerPerformer,
      overrideCustomerFeePerPerformer: payload.overrideCustomerFeePerPerformer,
    }

    this.updateSelectedVenues(venueDetails)

    runInAction(() => {
      this.currentVenue = venueDetails
      this.searchQuery = venueDetails.formatted_address
    })
  }

  @action.bound
  addSelectedVenue = async venue => {
    this.loading = true

    try {
      const { payload } = await this.rootAPI.venuesAPI.createVenue({
        jsonData: JSON.stringify(venue),
        cityId: this.rootStore.cityStore.selectedCityId,
      })

      const venueDetails = { ...venue, ...payload }

      this.updateSelectedVenues(venueDetails)

      runInAction(() => {
        this.currentVenue = venueDetails
        this.searchQuery = venueDetails.formatted_address
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    this.loading = false
  }

  @action.bound
  setMapObject(mapObject) {
    this.mapObject = mapObject
  }

  @action.bound
  async saveVenue(data) {
    this.loading = true
    const {
      overrideTravelPayoutPerPerformer,
      overrideCustomerFeePerPerformer,
      ...remainingData
    } = data

    try {
      const venueDetails = {
        ...this.currentVenue,
        ...data,
      }
      const res = await this.rootAPI.venuesAPI.updateVenue(
        this.currentVenue.id,
        {
          travelPayoutPerPerformer: overrideTravelPayoutPerPerformer,
          customerFeePerPerformer: overrideCustomerFeePerPerformer,
          ...remainingData,
        }
      )

      if (res) {
        this.currentVenue = venueDetails
        this.updateSelectedVenues(venueDetails)

        notification.success({
          message: 'Success!',
          description: 'Venue has been saved!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      if (err.code !== 0) {
        this.rootStore.errorsStore.addError(err)
      }
    }

    this.loading = false
  }

  @action.bound
  async getTravelChargesByCityId(cityId = this.selectedCityId) {
    this.getTravelChargesByCityIdLoading = true

    try {
      const { payload } =
        await this.rootAPI.venuesAPI.getTravelChargesByCityId(cityId)

      if (payload) {
        payload.sort((a, b) => a.minKm - b.minKm)

        this.venuesTravelCharges = payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getTravelChargesByCityIdLoading = false
    }
  }

  @action.bound
  setSearchQuery(searchQuery) {
    this.searchQuery = searchQuery
  }

  @action.bound
  clearCurrentVenue() {
    this.searchQuery = ''
    this.currentVenue = {}
  }
}
