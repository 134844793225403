export default class PerformanceAPI {
  constructor(args) {
    this.request = args.request
  }

  getPerformanceById = performanceId =>
    this.request.get(`/Performance/${performanceId}`)

  updatePerformance = data => this.request.put('/Performances', data)

  getPerformanceTypes = () => this.request.get('/Performances/Types')

  getPerformanceTypeById = performanceId =>
    this.request.get(`Performances/Type/${performanceId}`)

  updatePositionPayrate = (positionId, data) =>
    this.request.put(`/Performances/Position/${positionId}/UpdatePayrate`, data)
}
