import { action } from 'mobx'

import BaseErrorsStore from '@/flynk.app.web.core.data/stores/Errors/ErrorsStore'

export default class ErrorsStore extends BaseErrorsStore {
  @action.bound
  addError(error, options) {
    if (error) {
      if (
        error &&
        error.description &&
        error.description !== 'Role not found'
      ) {
        this.rootStore.alertStore.error({
          title: 'Error!',
          content: error.description,
          ...options,
        })
      }

      if (error.status === 401) {
        this.rootStore.authStore.logout()
      }
    }
  }
}
