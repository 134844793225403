export default class TaskAPI {
  constructor(args) {
    this.request = args.request
  }

  getTasks = data =>
    this.request.get('Tasks/', {
      params: data,
    })

  getTaskById = id => this.request.get(`Task/${id}`)

  createTask = data => this.request.post('Tasks/', data)

  updateTask = (id, data) => this.request.put(`Task/${id}`, data)

  deleteTask = id => this.request.delete(`Task/${id}`)

  getMyTasks = data =>
    this.request.get('Tasks/Notifications', {
      params: data,
    })

  changeTaskStatus = (id, data) =>
    this.request.put(`Task/${id}/ChangeStatus`, data)

  dismissTask = id => this.request.put(`Task/${id}/Dismiss`)

  updateReadTask = id => this.request.put(`Task/${id}/Read`)
}
