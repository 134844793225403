import GTM from '@/flynk.app.web.core.components/components/GTM'
import { setCookie } from '@/helpers/cookie'
import Debug from '@/helpers/debug'
import { unregister } from '@/registerServiceWorker'
import rootStore from '@/stores'

const configAPI = () => {
  rootStore.configStore.init({
    apiEndpoint: window.env
      ? window.env.REACT_APP_API_ENDPOINT
      : 'https://dev-id.flynk.com/api',
    authEndpoint: window.env
      ? window.env.REACT_APP_AUTH_ENDPOINT
      : 'https://dev-id.flynk.com/api',
    version: process.env.REACT_APP_API_VERSION,
    clientId: process.env.REACT_APP_CLIENT_ID,
    grantTypePassword: process.env.REACT_APP_GRANT_TYPE_PASSWORD,
    grantTypeRefreshToken: process.env.REACT_APP_REFRESH_TOKEN,
    appName: process.env.REACT_APP_NAME,
    supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
  })
}

const setSiteSource = () => {
  Debug.log('Save site source to cookie if have any')
  let siteSource = ''

  if (document.referrer) {
    if (document.referrer.indexOf(window.location.host) === -1) {
      siteSource = document.referrer
    }
  }

  if (siteSource) {
    setCookie('site-source', siteSource, 30)
  }
}

const initializeGTM = () => {
  Debug.log('Initialize GTM')
  const args = {
    gtmId: window.env ? window.env.REACT_APP_GTM_ID : '',
  }
  GTM.initialize(args)
}

export const init = () => {
  // Unregister service workers if registered before
  unregister()

  // Config api
  configAPI()

  // Save website user came from & insert Google Tag Manager
  setSiteSource()
  initializeGTM()
}
