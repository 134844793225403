import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button, Tooltip } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'

import { EMPTY_FIELD } from '@/flynk.app.web.core.components/constants'

import { formatName } from '../helpers/user'
import { MOMENT_DATE_FORMAT } from './common'
import { Theme } from './theme'

export const TaskType = {
  Undefined: 0,
  Manager: 10,
  Musician: 20,
  BandLeader: 30,
  Admin: 40,
  Client: 50,
}

export const TaskStatus = {
  None: 0,
  Open: 10,
  Inprogress: 20,
  Overdue: 30,
  Done: 40,
  Cancelled: 50,
}

export const TaskFilter = {
  Open: 10,
  Overdue: 20,
  RecentlyClosed: 30,
}

export const TaskStatusFilters = [
  {
    status: TaskFilter.Overdue,
    label: 'Overdue',
  },
  {
    status: TaskFilter.Open,
    label: 'Open',
  },
  {
    status: TaskFilter.RecentlyClosed,
    label: 'Recently Closed',
  },
]

export const TaskStatusData = {
  [TaskStatus.None]: {
    color: '#808080',
    title: 'None',
  },
  [TaskStatus.Open]: {
    color: '#52C41A',
    title: 'To Do',
  },
  [TaskStatus.Inprogress]: {
    color: '#44CCFF',
    title: 'In Progress',
  },
  [TaskStatus.Done]: {
    color: '#b7964e',
    title: 'Done',
  },
  [TaskStatus.Overdue]: {
    color: '#F8E71C',
    title: 'Overdue',
  },
  [TaskStatus.Cancelled]: {
    color: '#808080',
    title: 'Cancelled',
  },
}

export const TaskTableColumn = {
  DueDate: {
    title: 'Due Date',
    dataIndex: 'dueDate',
    render: (date, item) => {
      if (!date) return EMPTY_FIELD

      return (
        <>
          {moment(date).format(MOMENT_DATE_FORMAT)}
          {!isNil(item?.sendReminnderBeforeDueBy) && (
            <FontAwesomeIcon
              icon={['far', 'bell']}
              style={{ color: Theme.Colors.Primary, marginLeft: '10px' }}
            />
          )}
        </>
      )
    },
    width: 100,
  },
  Status: {
    title: 'Status',
    dataIndex: 'status',
    width: 100,
    render: status => {
      const { color, title } = TaskStatusData[status]

      return (
        <div className="status-cell">
          <div style={{ backgroundColor: color }} className="dot" /> {title}
        </div>
      )
    },
  },
  Summary: {
    title: 'Summary',
    dataIndex: 'summary',
    render: name => name,
  },
  Description: {
    title: 'Description',
    dataIndex: 'description',
    render: description => (
      <p
        style={{
          maxWidth: '500px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {description}
      </p>
    ),
  },
  Assignee: {
    title: 'Assignee',
    dataIndex: 'assignee',
    render: assignee => formatName(assignee),
    width: 200,
  },
  Actions: (onFinish, onRemove, onView) => ({
    title: 'Action',
    key: 'action',
    width: 100,
    render: (_, record) => (
      <div className="action-wrapper">
        <Tooltip title="Task Complete">
          <Button
            className="action-btn"
            onClick={e => {
              e.stopPropagation()
              onFinish(record)
            }}
          >
            <FontAwesomeIcon icon={['far', 'clipboard-check']} />
          </Button>
        </Tooltip>
        <Tooltip title="Dismiss this task from notification list">
          <Button
            className="action-btn delete-action-btn"
            onClick={e => {
              e.stopPropagation()

              onRemove(record)
            }}
          >
            <FontAwesomeIcon icon={['far', 'times-square']} />
          </Button>
        </Tooltip>
        {record?.bookingId && (
          <Tooltip title="Opens the related booking/ quote">
            <Button
              className="action-btn"
              onClick={e => {
                e.stopPropagation()
                onView(record)
              }}
            >
              <FontAwesomeIcon icon={['far', 'arrow-up-right-from-square']} />
            </Button>
          </Tooltip>
        )}
      </div>
    ),
  }),
}

export const DEFAULT_TASK_LIST_COLUMNS = [
  TaskTableColumn.DueDate,
  TaskTableColumn.Status,
  TaskTableColumn.Description,
  TaskTableColumn.Assignee,
]
