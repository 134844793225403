import { observer } from 'mobx-react'
import React from 'react'

import loadable from '@loadable/component'
import { sha512 } from 'js-sha512'

import QuoteLoading from '@/components/QuoteLoading'
import { URLS_NOT_SAVE_TO_LOCALSTORAGE, USER_MODES } from '@/constants'
import Loading from '@/flynk.app.web.core.components/components/Loading'
import {
  PublicRoute,
  Redirect,
  Route,
  Switch,
  withRouter,
} from '@/flynk.app.web.core.components/router'
import { useStores } from '@/helpers/common'
import PrivateRoute from '@/router/PrivateRoute'
import { history } from '@/stores'

import 'swiper/swiper.css'

const BackgroundLayout = loadable(() => import('../layouts/BackgroundLayout'))

const PackageDetailsContainer = loadable(
  () => import('../containers/QuoteBuilder/PackageDetailsContainer'),
  {
    fallback: <QuoteLoading />,
  }
)

const PackageEventContainer = loadable(
  () => import('../containers/QuoteBuilder/PackageEvent'),
  {
    fallback: <QuoteLoading />,
  }
)

const StaticCeremonyContainer = loadable(
  () => import('../containers/QuoteBuilder/StaticCeremonyContainer'),
  {
    fallback: <QuoteLoading />,
  }
)

const StaticSingersContainer = loadable(
  () => import('../containers/QuoteBuilder/StaticSingersContainer'),
  {
    fallback: <QuoteLoading />,
  }
)

const StaticReceptionContainer = loadable(
  () => import('../containers/QuoteBuilder/StaticReceptionContainer'),
  {
    fallback: <QuoteLoading />,
  }
)

const StaticRequestCallbackModalContainer = loadable(
  () =>
    import('../containers/QuoteBuilder/StaticRequestCallBackModalContainer'),
  {
    fallback: <QuoteLoading />,
  }
)

const FirstQuoteBuilderPageContainer = loadable(
  () => import('../containers/QuoteBuilder/FirstQuoteBuilderPageContainer'),
  {
    fallback: <Loading />,
  }
)

const PublicLayout = loadable(() => import('../layouts/PublicLayout'))

const BlankLayout = loadable(() => import('../layouts/BlankLayout'))

const TransparentLayout = loadable(() => import('../layouts/TransparentLayout'))

const AuthenticatedLayout = loadable(
  () => import('../layouts/AuthenticatedLayout')
)

const LoginContainer = loadable(
  () => import('../containers/Auth/Login/LoginContainer'),
  {
    fallback: <Loading />,
  }
)

const RegisterContainer = loadable(
  () => import('../containers/Auth/Register/RegisterContainer'),
  {
    fallback: <Loading />,
  }
)

const RegisterSalesManagerContainer = loadable(
  () => import('../containers/Auth/Register/RegisterSalesManagerContainer'),
  {
    fallback: <Loading />,
  }
)

const SMTermsConditionsContainer = loadable(
  () => import('../containers/Auth/Register/SMTermsConditionsContainer'),
  {
    fallback: <Loading />,
  }
)

const MTermsConditionsContainer = loadable(
  () => import('../containers/Auth/Register/MTermsConditionsContainer'),
  {
    fallback: <Loading />,
  }
)

const RegisterMusicianContainer = loadable(
  () => import('../containers/Auth/Register/RegisterMusicianContainer'),
  {
    fallback: <Loading />,
  }
)

const ReacceptTermsAndConditions = loadable(
  () => import('../components/Auth/Register/ReacceptTermsAndConditions'),
  {
    fallback: <Loading />,
  }
)

const ForgotPasswordContainer = loadable(
  () => import('../containers/Auth/ResetPassword/ForgotPasswordContainer'),
  {
    fallback: <Loading />,
  }
)

const ResetPasswordContainer = loadable(
  () => import('../containers/Auth/ResetPassword/ResetPasswordContainer'),
  {
    fallback: <Loading />,
  }
)

const ForgotPasswordSent = loadable(
  () =>
    import(
      '../flynk.app.web.core.components/components/Auth/ResetPassword/ForgotPasswordSent'
    ),
  {
    fallback: <Loading />,
  }
)

const ForgotUsernameContainer = loadable(
  () => import('../containers/Auth/ForgotUsername/ForgotUsernameContainer'),
  {
    fallback: <Loading />,
  }
)

const ForgotUsernameSent = loadable(
  () =>
    import(
      '../flynk.app.web.core.components/components/Auth/ForgotUsername/ForgotUsernameSent'
    ),
  {
    fallback: <Loading />,
  }
)

const DashboardContainer = loadable(
  () => import('../containers/Dashboard/DashboardContainer'),
  {
    fallback: <Loading />,
  }
)

const OrganisationListContainer = loadable(
  () => import('../containers/Organisations/OrganisationListContainer'),
  {
    fallback: <Loading />,
  }
)

const OrganisationMemberListContainer = loadable(
  () => import('../containers/Organisations/OrganisationMemberListContainer'),
  {
    fallback: <Loading />,
  }
)

const OrganisationMemberDetailContainer = loadable(
  () => import('../containers/Organisations/OrganisationMemberDetailContainer'),
  {
    fallback: <Loading />,
  }
)

const OrganisationDetailContainer = loadable(
  () => import('../containers/Organisations/OrganisationDetailContainer'),
  {
    fallback: <Loading />,
  }
)

const AddOrganisationContainer = loadable(
  () => import('../containers/Organisations/AddOrganisationContainer'),
  {
    fallback: <Loading />,
  }
)

const EditOrganisationContainer = loadable(
  () => import('../containers/Organisations/EditOrganisationContainer'),
  {
    fallback: <Loading />,
  }
)

const UserListContainer = loadable(
  () => import('../containers/Users/UserListContainer'),
  {
    fallback: <Loading />,
  }
)

const UserInfoContainer = loadable(
  () => import('../containers/Users/UserInfoContainer'),
  {
    fallback: <Loading />,
  }
)

const AddUserFormContainer = loadable(
  () => import('../containers/Users/AddUserFormContainer'),
  {
    fallback: <Loading />,
  }
)

const EditUserFormContainer = loadable(
  () => import('../containers/Users/EditUserFormContainer'),
  {
    fallback: <Loading />,
  }
)

const ValidateEmailContainer = loadable(
  () => import('../containers/Users/ValidateEmailContainer'),
  {
    fallback: <Loading />,
  }
)

const ValidateMobileContainer = loadable(
  () => import('../containers/Users/ValidateMobileContainer'),
  {
    fallback: <Loading />,
  }
)

const AcceptRoleContainer = loadable(
  () => import('../containers/Users/AcceptRoleContainer'),
  {
    fallback: <Loading />,
  }
)

const UnsubscribeMarketingMailContainer = loadable(
  () => import('../containers/Users/UnsubscribeMarketingMailContainer'),
  {
    fallback: <Loading />,
  }
)

const InvoiceListContainer = loadable(
  () => import('../containers/Invoices/InvoiceListContainer'),
  {
    fallback: <Loading />,
  }
)

const InvoiceDetailContainer = loadable(
  () => import('../containers/Invoices/InvoiceDetailContainer'),
  {
    fallback: <Loading />,
  }
)

const InvoiceFormContainer = loadable(
  () => import('../containers/Invoices/InvoiceFormContainer'),
  {
    fallback: <Loading />,
  }
)

const IssueListContainer = loadable(
  () => import('../containers/Issues/IssueListContainer'),
  {
    fallback: <Loading />,
  }
)

const IssueDetailContainer = loadable(
  () => import('../containers/Issues/IssueDetailContainer'),
  {
    fallback: <Loading />,
  }
)

const IssueFormContainer = loadable(
  () => import('../containers/Issues/IssueFormContainer'),
  {
    fallback: <Loading />,
  }
)

const ClientAppListContainer = loadable(
  () => import('../containers/ClientApps/ClientAppListContainer'),
  {
    fallback: <Loading />,
  }
)

const ClientAppShortlistListContainer = loadable(
  () => import('../containers/ClientApps/ClientAppShortlistListContainer'),
  {
    fallback: <Loading />,
  }
)

const ClientAppFormContainer = loadable(
  () => import('../containers/ClientApps/ClientAppFormContainer'),
  {
    fallback: <Loading />,
  }
)

const ClientAppDetailContainer = loadable(
  () => import('../containers/ClientApps/ClientAppDetailContainer'),
  {
    fallback: <Loading />,
  }
)

const ClientAppFeatureListContainer = loadable(
  () => import('../containers/ClientAppFeatures/ClientAppFeatureListContainer'),
  {
    fallback: <Loading />,
  }
)

const ClientAppFeatureFormContainer = loadable(
  () => import('../containers/ClientAppFeatures/ClientAppFeatureFormContainer'),
  {
    fallback: <Loading />,
  }
)

const BankListContainer = loadable(
  () => import('../containers/Banks/BankListContainer'),
  {
    fallback: <Loading />,
  }
)

const BankFormContainer = loadable(
  () => import('../containers/Banks/BankFormContainer'),
  {
    fallback: <Loading />,
  }
)

const SupplierListContainer = loadable(
  () => import('../containers/Suppliers/SupplierListContainer'),
  {
    fallback: <Loading />,
  }
)

const SupplierFormContainer = loadable(
  () => import('../containers/Suppliers/SupplierFormContainer'),
  {
    fallback: <Loading />,
  }
)

const InboxContainer = loadable(
  () => import('../containers/Inbox/InboxContainer'),
  {
    fallback: <Loading />,
  }
)

const PaymentListContainer = loadable(
  () => import('../containers/Payments/PaymentListContainer'),
  {
    fallback: <Loading />,
  }
)

const PaymentFormContainer = loadable(
  () => import('../containers/Payments/PaymentFormContainer'),
  {
    fallback: <Loading />,
  }
)

const ProfileContainer = loadable(
  () => import('../containers/Profile/ProfileContainer'),
  {
    fallback: <Loading />,
  }
)

const SettingsContainer = loadable(
  () => import('../containers/Settings/SettingsContainer'),
  {
    fallback: <Loading />,
  }
)

const HelpContainer = loadable(
  () => import('../containers/Help/HelpContainer'),
  {
    fallback: <Loading />,
  }
)

const BookingSheetContainer = loadable(
  () => import('../containers/Bookings/BookingSheet/BookingSheetContainer'),
  {
    fallback: <Loading />,
  }
)

const Exception404 = loadable(
  () => import('../flynk.app.web.core.components/components/Exception/404'),
  {
    fallback: <Loading />,
  }
)

// const QuoteStepListContainer = loadable(()
// => import('../containers/QuoteBuilder/StepListContainer'), {
//   fallback: <Loading />,
// })

const ProspectListContainer = loadable(
  () => import('../containers/Prospects/ProspectListContainer'),
  {
    fallback: <Loading />,
  }
)

const BookingListContainer = loadable(
  () => import('../containers/Bookings/BookingListContainer'),
  {
    fallback: <Loading />,
  }
)

const GigRosterContainer = loadable(
  () => import('../containers/GigRoster/GigRosterContainer'),
  {
    fallback: <Loading />,
  }
)

const BookingDetailsContainer = loadable(
  () => import('../containers/Bookings/BookingDetailsContainer'),
  {
    fallback: <Loading />,
  }
)

const TasksContainer = loadable(
  () => import('../containers/Tasks/TaskContainer'),
  {
    fallback: <Loading />,
  }
)

const OfferDetailContainer = loadable(
  () => import('../containers/Offers/OfferDetailContainer'),
  {
    fallback: <Loading />,
  }
)

const BookingDetailsConfirmationContainer = loadable(
  () => import('../containers/Bookings/BookingDetailsConfirmationContainer'),
  {
    fallback: <Loading />,
  }
)

const BookingDetailsMusiciansContainer = loadable(
  () => import('../containers/Bookings/BookingDetailsMusiciansContainer'),
  {
    fallback: <Loading />,
  }
)

const BookingDetailsTimelineContainer = loadable(
  () => import('../containers/Bookings/BookingDetailsTimelineContainer'),
  {
    fallback: <Loading />,
  }
)

const BookingDetailsMusicsContainer = loadable(
  () => import('../containers/Bookings/BookingDetailsMusicsContainer'),
  {
    fallback: <Loading />,
  }
)

const BookingDetailsPaymentsContainer = loadable(
  () => import('../containers/Bookings/BookingDetailsPaymentsContainer'),
  {
    fallback: <Loading />,
  }
)

const MusicianBiosListContainer = loadable(
  () => import('../containers/Artists/MusicianBiosListContainer'),
  {
    fallback: <Loading />,
  }
)

const MusicianBiosDetailsContainer = loadable(
  () => import('../containers/Artists/MusicianBiosDetailsContainer'),
  {
    fallback: <Loading />,
  }
)

const PreferenceListContainer = loadable(
  () => import('../containers/Artists/PreferenceListContainer'),
  {
    fallback: <Loading />,
  }
)

const VenuesContainer = loadable(
  () => import('../containers/Venues/VenuesContainer'),
  {
    fallback: <Loading />,
  }
)

const ReportListContainer = loadable(
  () => import('../containers/Reports/ReportListContainer'),
  {
    fallback: <Loading />,
  }
)

const OfferContainer = loadable(
  () => import('../containers/Offers/OfferContainer'),
  {
    fallback: <Loading />,
  }
)

const MusicianBookingListContainer = loadable(
  () => import('../containers/Musicians/MusicianBookingListContainer'),
  {
    fallback: <Loading />,
  }
)

const MusicianInvoicesContainer = loadable(
  () => import('../containers/Musicians/MusicianInvoicesContainer'),
  {
    fallback: <Loading />,
  }
)

const MusicianMessagesContainer = loadable(
  () => import('../containers/Musicians/MusicianMessagesContainer'),
  {
    fallback: <Loading />,
  }
)

const SingersContainer = loadable(
  () => import('../containers/Artists/SingersContainer'),
  {
    fallback: <Loading />,
  }
)

const Routes = () => {
  const {
    location: { pathname },
  } = history
  const {
    cityStore: { selectedCityId },
    profileStore: { profile },
    authStore: { isAuthenticated },
  } = useStores()

  const username = profile.user?.username

  let data

  if (username) {
    const hash = sha512(username)

    if (selectedCityId) {
      data = {
        pathname,
        selectedCityId,
      }
    } else {
      data = { pathname }
    }

    if (!URLS_NOT_SAVE_TO_LOCALSTORAGE.some(url => pathname.match(url))) {
      localStorage.setItem(hash, JSON.stringify(data))
    }
  }

  const redirectPath = isAuthenticated ? '/prospects' : '/login'

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={redirectPath} />} />
      <Route
        exact
        path="/musician_bios/"
        render={() => <Redirect to="/musicians" />}
      />
      <PublicRoute
        exact
        path="/login"
        component={LoginContainer}
        docTitle="Backstage Login | Red Soda Band"
        layout={BackgroundLayout}
      />
      <PrivateRoute
        exact
        path="/register"
        component={RegisterContainer}
        docTitle="Register"
        layout={BackgroundLayout}
        mode={USER_MODES.SysAdmin}
      />
      <PublicRoute
        exact
        path="/csmr"
        component={RegisterSalesManagerContainer}
        docTitle="Complete Sales Manager Registration"
        layout={BackgroundLayout}
      />
      <PublicRoute
        exact
        path="/smtc"
        component={SMTermsConditionsContainer}
        docTitle="Sales Manager Terms and conditions"
        layout={BackgroundLayout}
      />
      <PublicRoute
        exact
        path="/mtc"
        component={MTermsConditionsContainer}
        docTitle="Musician Terms and conditions"
        layout={BackgroundLayout}
      />
      <PublicRoute
        exact
        path="/cmr"
        component={RegisterMusicianContainer}
        docTitle="Complete Musician Registration"
        layout={BackgroundLayout}
      />
      <PublicRoute
        exact
        path="/forgot-password"
        component={ForgotPasswordContainer}
        docTitle="Forgot Password"
        layout={BackgroundLayout}
      />
      <PublicRoute
        exact
        path="/rp"
        component={ResetPasswordContainer}
        docTitle="Reset Password"
        layout={BackgroundLayout}
      />
      <PublicRoute
        exact
        path="/forgot-password-sent"
        component={ForgotPasswordSent}
        docTitle="Forgot Password Sent"
        layout={BlankLayout}
      />
      <PublicRoute
        exact
        path="/forgot-username"
        component={ForgotUsernameContainer}
        docTitle="Forgot Username"
        layout={BackgroundLayout}
      />
      <PublicRoute
        exact
        path="/forgot-username-sent"
        component={ForgotUsernameSent}
        docTitle="Forgot Username Sent"
        layout={BlankLayout}
      />
      <PrivateRoute
        mode={USER_MODES.Individual}
        layout={BackgroundLayout}
        exact
        path="/reaccept-tc"
        component={ReacceptTermsAndConditions}
        docTitle="Terms & Conditions"
      />
      <PrivateRoute
        mode={USER_MODES.Individual}
        layout={AuthenticatedLayout}
        exact
        path="/dashboard"
        component={DashboardContainer}
        docTitle="Dashboard"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/organisations"
        component={OrganisationListContainer}
        docTitle="Organisations"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/organisations/add"
        component={AddOrganisationContainer}
        docTitle="Add Organisation"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/organisations/:orgId/users"
        component={OrganisationMemberListContainer}
        docTitle="Organisation Users"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/organisations/:orgId/users/:memberId"
        component={OrganisationMemberDetailContainer}
        docTitle="User Details"
      />
      <PrivateRoute
        mode={USER_MODES.OrgAdmin}
        layout={AuthenticatedLayout}
        exact
        path="/organisations/:orgId/edit"
        component={EditOrganisationContainer}
        docTitle="Edit Organisation"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/organisations/:orgId"
        component={OrganisationDetailContainer}
        docTitle="Organisation Detail"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/users"
        component={UserListContainer}
        docTitle="Users"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/users/add"
        component={AddUserFormContainer}
        docTitle="Add User"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/users/:id"
        component={UserInfoContainer}
        docTitle="User Details"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/users/:id/edit"
        component={EditUserFormContainer}
        docTitle="Edit User"
      />
      <PublicRoute
        layout={BlankLayout}
        exact
        path="/ve"
        component={ValidateEmailContainer}
        docTitle="Validate Email"
      />
      <PublicRoute
        layout={BlankLayout}
        exact
        path="/vm"
        component={ValidateMobileContainer}
        docTitle="Validate Mobile"
      />
      <PublicRoute
        layout={BlankLayout}
        exact
        path="/ar"
        component={AcceptRoleContainer}
        docTitle="Accept Role"
      />
      <PublicRoute
        layout={BlankLayout}
        exact
        path="/unsbmke"
        component={UnsubscribeMarketingMailContainer}
        docTitle="Unsubscribe Request"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/invoices"
        component={InvoiceListContainer}
        docTitle="Invoices"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/invoices/add"
        component={InvoiceFormContainer}
        docTitle="Add Invoice"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/invoices/:id"
        component={InvoiceDetailContainer}
        docTitle="Invoice Detail"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/invoices/:id/edit"
        component={InvoiceFormContainer}
        docTitle="Edit Invoice"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/issues"
        component={IssueListContainer}
        docTitle="Issues"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/issues/add"
        component={IssueFormContainer}
        docTitle="Add Issue"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/issues/:id"
        component={IssueDetailContainer}
        docTitle="Issue Detail"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/issues/:id/edit"
        component={IssueFormContainer}
        docTitle="Edit Issue"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/banks"
        component={BankListContainer}
        docTitle="Banks"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/banks/add"
        component={BankFormContainer}
        docTitle="Add Bank"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/banks/:id/edit"
        component={BankFormContainer}
        docTitle="Edit Bank"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/suppliers"
        component={SupplierListContainer}
        docTitle="Suppliers"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/suppliers/add"
        component={SupplierFormContainer}
        docTitle="Add Supplier"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/suppliers/:id/edit"
        component={SupplierFormContainer}
        docTitle="Edit Supplier"
      />
      <PrivateRoute
        mode={USER_MODES.OrgAdmin}
        layout={AuthenticatedLayout}
        exact
        path="/client-apps"
        component={ClientAppListContainer}
        docTitle="Client Apps"
      />
      <PrivateRoute
        mode={USER_MODES.OrgAdmin}
        layout={AuthenticatedLayout}
        exact
        path="/shortlist-client-apps"
        component={ClientAppShortlistListContainer}
        docTitle="Shortlist Client Apps"
      />
      <PrivateRoute
        mode={USER_MODES.OrgAdmin}
        layout={AuthenticatedLayout}
        exact
        path="/client-apps/add"
        component={ClientAppFormContainer}
        docTitle="Add Client App"
      />
      <PrivateRoute
        mode={USER_MODES.OrgAdmin}
        layout={AuthenticatedLayout}
        exact
        path="/client-apps/:id"
        component={ClientAppDetailContainer}
        docTitle="Client App Detail"
      />
      <PrivateRoute
        mode={USER_MODES.OrgAdmin}
        layout={AuthenticatedLayout}
        exact
        path="/client-apps/:id/edit"
        component={ClientAppFormContainer}
        docTitle="Edit Client App"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/inbox"
        component={InboxContainer}
        docTitle="Inbox"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/payments"
        component={PaymentListContainer}
        docTitle="Payments"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/payments/add"
        component={PaymentFormContainer}
        docTitle="Add Payment"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/payments/:id/edit"
        component={PaymentFormContainer}
        docTitle="Edit Payment"
      />
      <PrivateRoute
        mode={USER_MODES.Individual}
        layout={AuthenticatedLayout}
        exact
        path="/profile"
        component={ProfileContainer}
        docTitle="Profile"
      />
      <PrivateRoute
        mode={USER_MODES.OrgAdmin}
        layout={AuthenticatedLayout}
        exact
        path="/settings"
        component={SettingsContainer}
        docTitle="Settings"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/settings/client-app-features"
        component={ClientAppFeatureListContainer}
        docTitle="Settings Client App Features"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/settings/client-app-features/add"
        component={ClientAppFeatureFormContainer}
        docTitle="Settings Add Client Feature"
      />
      <PrivateRoute
        mode={USER_MODES.SupportManager}
        layout={AuthenticatedLayout}
        exact
        path="/settings/client-app-features/:id/edit"
        component={ClientAppFeatureFormContainer}
        docTitle="Settings Edit Client Feature"
      />
      <PrivateRoute
        mode={USER_MODES.Individual}
        layout={AuthenticatedLayout}
        exact
        path="/help"
        component={HelpContainer}
        docTitle="Help"
      />
      {/* <PublicRoute
      path="/booking"
      component={QuoteStepListContainer}
      docTitle="Prices+Bookings | Red Soda Band"
      layout={BlankLayout}
    /> */}
      <PublicRoute
        path="/quote/event"
        component={PackageEventContainer}
        docTitle="Package Details | Red Soda Band"
        layout={PublicLayout}
      />
      <PublicRoute
        path="/quote"
        component={PackageDetailsContainer}
        docTitle="Package Details | Red Soda Band"
        layout={PublicLayout}
      />
      {/* booking is old route for static site. currently we change it into quote.
      so will rediect to quote if user access to booking  */}
      <Route exact path="/booking" render={() => <Redirect to="/quote" />} />
      <Route
        exact
        path="/booking/user"
        render={() => <Redirect to="/quote" />}
      />
      {/* use this route for static site */}
      <PublicRoute
        path="/:cityname/static-ceremony"
        component={StaticCeremonyContainer}
        docTitle="Package Details | Red Soda Band"
        layout={PublicLayout}
      />
      {/* use this route for static site */}
      <PublicRoute
        path="/:cityname/static-singers"
        component={StaticSingersContainer}
        docTitle="Package Details | Red Soda Band"
        layout={PublicLayout}
      />
      {/* use this route for static site */}
      <PublicRoute
        path="/:cityname/static-reception"
        component={StaticReceptionContainer}
        docTitle="Package Details | Red Soda Band"
        layout={PublicLayout}
      />
      {/* use this route for static site */}
      <PublicRoute
        path="/:cityname/request-callback"
        component={StaticRequestCallbackModalContainer}
        docTitle="Package Details | Red Soda Band"
        layout={TransparentLayout}
      />
      <PublicRoute
        path="/quote_builder"
        component={FirstQuoteBuilderPageContainer}
        docTitle="Package Details | Red Soda Band"
        layout={PublicLayout}
      />
      <PublicRoute
        exact
        path="/booking_sheet"
        component={BookingSheetContainer}
        docTitle="Booking Sheet"
        layout={BlankLayout}
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/prospects"
        component={ProspectListContainer}
        docTitle="Prospects"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/bookings"
        component={BookingListContainer}
        docTitle="Bookings"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/bookings/:id"
        component={BookingListContainer}
        docTitle="Booking"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/bookings/:id/details"
        component={BookingDetailsContainer}
        docTitle="Booking Details"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/tasks"
        component={TasksContainer}
        docTitle="Tasks"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/gig_roster"
        component={GigRosterContainer}
        docTitle="Gig Roster"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/bookings/:id/confirmation"
        component={BookingDetailsConfirmationContainer}
        docTitle="Booking Confirmation"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/bookings/:id/musicians"
        component={BookingDetailsMusiciansContainer}
        docTitle="Booking Musicians"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/bookings/:id/timeline"
        component={BookingDetailsTimelineContainer}
        docTitle="Booking Timeline"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/bookings/:id/musics"
        component={BookingDetailsMusicsContainer}
        docTitle="Booking Musics"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/bookings/:id/payments"
        component={BookingDetailsPaymentsContainer}
        docTitle="Booking Musicians Payments"
      />
      {/* <PrivateRoute
    mode={USER_MODES.OrgMember}
    layout={AuthenticatedLayout}
    exact
    path="/bookings/:id/reviews"
    component={BookingDetailsReviewsContainer}
    docTitle="Booking Reviews"
  /> */}
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musicians"
        component={MusicianBiosListContainer}
        docTitle="Musicians"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician_bios/:id"
        component={MusicianBiosDetailsContainer}
        docTitle="Artists"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/preference_list"
        component={PreferenceListContainer}
        docTitle="Preferred Musicians List"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/singers"
        component={SingersContainer}
        docTitle="Singers"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/venues"
        component={VenuesContainer}
        docTitle="Venues"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/reports"
        component={ReportListContainer}
        docTitle="Reports"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/offers"
        component={OfferContainer}
        docTitle="Offers"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/offers/:id/details"
        component={OfferDetailContainer}
        docTitle="Offer Details"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/bookings"
        component={MusicianBookingListContainer}
        docTitle="Bookings"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/bookings/:id"
        component={MusicianBookingListContainer}
        docTitle="Bookings"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/bookings/:id/details"
        component={BookingDetailsContainer}
        docTitle="Booking Details"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/bookings/:id/musicians"
        component={BookingDetailsMusiciansContainer}
        docTitle="Booking Musicians"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/bookings/:id/timeline"
        component={BookingDetailsTimelineContainer}
        docTitle="Booking Timeline"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/bookings/:id/musics"
        component={BookingDetailsMusicsContainer}
        docTitle="Booking Musics"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/preferences"
        component={MusicianBiosDetailsContainer}
        docTitle="Preferences"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/preference_list"
        component={PreferenceListContainer}
        docTitle="Preferred Musicians List"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/invoices"
        component={MusicianInvoicesContainer}
        docTitle="Invoices"
      />
      <PrivateRoute
        mode={USER_MODES.OrgMember}
        layout={AuthenticatedLayout}
        exact
        path="/musician/messages"
        component={MusicianMessagesContainer}
        docTitle="Messages"
      />
      <PublicRoute
        layout={BlankLayout}
        path="*"
        component={Exception404}
        docTitle="404 - Not Found"
      />
    </Switch>
  )
}

export default withRouter(observer(Routes))
