import { Theme } from './theme'

export const defaultVenueTravelChargeStyles = {
  0: null,
  1: Theme.Colors.Primary,
  100: '#B76A4E',
  300: '#B74E4E',
}

const citiesDefaultCoordinates = {
  Boston: {
    lat: 42.3600825,
    lng: -71.0588801,
  },
  Sydney: {
    lat: -33.8688197,
    lng: 151.2092955,
  },
  Default: {
    lat: -33.8688197,
    lng: 151.2092955,
  },
  Toronto: {
    lat: 43.653226,
    lng: -79.3831843,
  },
}

export const getCitiesDefaultCoordinates = name => {
  if (citiesDefaultCoordinates[name]) return citiesDefaultCoordinates[name]

  return citiesDefaultCoordinates.Default
}
