import BankAPI from '@/flynk.app.web.core.data/api/BankAPI'
import ClientAppAPI from '@/flynk.app.web.core.data/api/ClientAppAPI'
import ClientAppFeatureAPI from '@/flynk.app.web.core.data/api/ClientAppFeatureAPI'
import ClientAppShortlistAPI from '@/flynk.app.web.core.data/api/ClientAppShortlistAPI'
import InvoiceAPI from '@/flynk.app.web.core.data/api/InvoiceAPI'
import IssueAPI from '@/flynk.app.web.core.data/api/IssueAPI'
import OrganisationAPI from '@/flynk.app.web.core.data/api/OrganisationAPI'
import PaymentAPI from '@/flynk.app.web.core.data/api/PaymentAPI'
import ProductAPI from '@/flynk.app.web.core.data/api/ProductAPI'
import ProfileAPI from '@/flynk.app.web.core.data/api/ProfileAPI'
import SupplierAPI from '@/flynk.app.web.core.data/api/SupplierAPI'
import Request from '@/flynk.app.web.core.data/helpers/requests'

import AuthAPI from './AuthAPI'
import BookingAdminAPI from './BookingAdminAPI'
import BookingAPI from './BookingAPI'
import OfferAPI from './OfferAPI'
import PerformanceAPI from './PerformanceAPI'
import PerformerAPI from './PerformerAPI'
import ProspectAPI from './ProspectAPI'
import ReportAPI from './ReportAPI'
import TaskAPI from './TaskAPI'
import UserAPI from './UserAPI'
import VenuesAPI from './VenuesAPI'

export default class RootAPI {
  constructor(args) {
    const { rootStore } = args
    const request = new Request({ rootStore })

    this.authAPI = new AuthAPI({ request, rootStore })
    this.bankAPI = new BankAPI({ request, rootStore })
    this.bookingAPI = new BookingAPI({ request, rootStore })
    this.bookingAdminAPI = new BookingAdminAPI({ request, rootStore })
    this.clientAppAPI = new ClientAppAPI({ request, rootStore })
    this.clientAppFeatureAPI = new ClientAppFeatureAPI({ request, rootStore })
    this.clientAppShortlistAPI = new ClientAppShortlistAPI({
      request,
      rootStore,
    })
    this.invoiceAPI = new InvoiceAPI({ request, rootStore })
    this.issueAPI = new IssueAPI({ request, rootStore })
    this.offerAPI = new OfferAPI({ request, rootStore })
    this.organisationAPI = new OrganisationAPI({ request, rootStore })
    this.paymentAPI = new PaymentAPI({ request, rootStore })
    this.performanceAPI = new PerformanceAPI({ request, rootStore })
    this.performerAPI = new PerformerAPI({ request, rootStore })
    this.productAPI = new ProductAPI({ request, rootStore })
    this.profileAPI = new ProfileAPI({ request, rootStore })
    this.prospectAPI = new ProspectAPI({ request, rootStore })
    this.supplierAPI = new SupplierAPI({ request, rootStore })
    this.userAPI = new UserAPI({ request, rootStore })
    this.venuesAPI = new VenuesAPI({ request, rootStore })
    this.reportAPI = new ReportAPI({ request, rootStore })
    this.taskAPI = new TaskAPI({ request, rootStore })
  }
}
