import { MobXProviderContext } from 'mobx-react'
import React from 'react'

import { Modal } from 'antd'
import { TIME_AMPM_FORMAT } from 'flynk.app.web.core.components/constants'
import { USER_STATUSES } from 'flynk.app.web.core.data/constants'
import HTMLDecoderEncoder from 'html-encoder-decoder'
import { sha512 } from 'js-sha512'
import parsePhoneNumberFromString, {
  getNumberType,
  parse,
} from 'libphonenumber-js'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { toJS } from 'mobx'
import moment from 'moment'

import { isEmptyObject } from '@/flynk.app.web.core.data/helpers'

const { confirm } = Modal

export function useStores() {
  return React.useContext(MobXProviderContext)
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const isListChanged = (preferredPerformers, oldPerformers) => {
  const preferredPerformerIds = preferredPerformers.map(p => p.id)
  const oldPerformerIds = oldPerformers.map(p => p.id)

  return !isEqual(preferredPerformerIds, oldPerformerIds)
}

export const getPerformanceTime = (offer = []) => {
  let { arrivalTime, endTime } = offer[0]?.position

  offer.forEach(item => {
    if (moment(item?.position?.arrivalTime).isBefore(arrivalTime)) {
      arrivalTime = item?.position?.arrivalTime
    }

    if (moment(item?.position?.endTime).isAfter(endTime)) {
      endTime = item?.position?.endTime
    }
  })

  if (!arrivalTime) {
    const offerWithStartTime = offer.find(el => el.position?.arrivalTime)
    arrivalTime = offerWithStartTime && offerWithStartTime.position.arrivalTime
  }

  if (!endTime) {
    const offerWithEndTime = offer.reverse().find(el => el.position?.endTime)
    endTime = offerWithEndTime && offerWithEndTime.position.endTime
  }

  if (!arrivalTime || !endTime) {
    return []
  }

  return [arrivalTime, endTime]
}

export const getFormattedTimeArray = arr => {
  if (Array.isArray(arr)) {
    return arr.map(el => {
      if (!el || typeof el !== 'string') {
        return el
      }

      return moment(el).format(TIME_AMPM_FORMAT)
    })
  }

  return arr
}

export const checkOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}) => ({
  v: scrollHeight > clientHeight,
  h: scrollWidth > clientWidth,
})

export const activateScrollBar = () => {
  const scrollables = document.querySelectorAll(
    '.rk-scrollbar-horizontal-table .ant-table'
  )
  ;[].forEach.call(scrollables, scrollable => {
    const isOverfown = checkOverflown(scrollable)
    const activeClass = 'rk-inactive'

    if (isOverfown.h && scrollable.classList.contains(activeClass)) {
      scrollable.classList.remove(activeClass)
    } else if (!isOverfown.h && !scrollable.classList.contains(activeClass)) {
      scrollable.classList.add(activeClass)
    }
  })
}

// for debugging
export const unPack = value => toJS(value, { recurseEverything: true })

export const getLocationSummary = location => {
  if (location) {
    return location.name
      ? location.name
      : [location.city, location.state].join(' ')
  }

  return ''
}

export const formatCitiesByRoleStatus = (role, cities) =>
  cities.map(city => {
    const correspondingRole = role.orgId === city.id
    if (!correspondingRole) return city

    return {
      ...city,
      isRoleActive: role.status === USER_STATUSES.Enabled,
    }
  })

export const conditionedClassName = (...args) => {
  const classes = []

  args.forEach(arg => {
    if (!arg) return

    if (typeof arg === 'string' || typeof arg === 'number') {
      classes.push(arg)
    } else if (Array.isArray(arg)) {
      classes.push(conditionedClassName(...arg))
    } else if (typeof arg === 'object') {
      Object.entries(arg).forEach(([key, value]) => {
        if (value) {
          classes.push(key)
        }
      })
    }
  })

  return classes.join(' ')
}

export const isJson = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}

export const getObjectFromLocalStorage = key => {
  const storageItem = localStorage.getItem(key)

  if (isJson(storageItem)) {
    return JSON.parse(storageItem)
  }

  return null
}

export const getFromLocalStorageObjectByKey = (objectName, key) => {
  const object = getObjectFromLocalStorage(objectName)

  if (!object) return null

  if (typeof object === 'object') return object[key]

  return object
}

export const getUserNameDataFromLocalStorage = username => {
  try {
    const hash = sha512(username)

    const localStorageData = localStorage.getItem(hash)

    if (!localStorageData) return {}

    const localStorageUserNameData = JSON.parse(localStorageData)

    if (!localStorageUserNameData) return {}

    return localStorageUserNameData
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e)

    return {}
  }
}

export const getHtmlDecoded = text =>
  text ? HTMLDecoderEncoder.decode(text) : text

export const getHtmlEncoded = text =>
  text ? HTMLDecoderEncoder.encode(text) : text

export const getFullName = (...args) => {
  if (!args?.length) return ''
  let arr = []

  if (typeof args[0] === 'object') {
    const { firstname, firstName, lastname, lastName } = args[0]

    arr = [firstname ?? firstName, lastname ?? lastName]
  } else if (typeof args[0] === 'string') {
    arr = args.splice(0, 2)
  }

  return arr.filter(el => el).join(' ')
}

export const getRegionShortCodeByCityName = cityName => {
  switch (cityName) {
    case 'Boston':
      return 'US'
    case 'Toronto':
      return 'CA'
    default:
      return 'AU'
  }
}

export const groupBy = (arr, key) => {
  const initialValue = {}

  return arr.reduce((acc, cval) => {
    const myAttribute = cval[key]
    acc[myAttribute] = [...(acc[myAttribute] || []), cval]

    return acc
  }, initialValue)
}

export const postAMessageFromIframeToParent = (message, value = '') => {
  // incase it's a iframe. post a message to detect button is clicked
  window.parent.postMessage({ message, value }, '*')
}

export const orderByCreatedDate = (array, order = 'desc') =>
  array.slice().sort((a, b) => {
    const dateA = Date.parse(a?.created || 0)
    const dateB = Date.parse(b?.created || 0)

    if (order === 'asc') {
      return dateA - dateB
    }

    return dateB - dateA
  })

export const toCapitalize = text =>
  text?.charAt(0)?.toUpperCase() + text?.slice(1)

export const checkIsDirty = (initialValues, currentValues) =>
  !isEqual(initialValues, currentValues)

export const validateFormFields = async (formInstance, fields) => {
  try {
    await formInstance.validateFields(fields)

    return true
  } catch (error) {
    if (error?.errorFields?.length) {
      return false
    }

    return true
  }
}

// REDS-4320
export const formatNationalPhoneNumber = (phone, region) => {
  if (!phone || !region) return phone

  const phoneNumber = parsePhoneNumberFromString(phone, region?.toUpperCase())

  return phoneNumber ? phoneNumber.formatNational() : null
}

export const isValidLandlineNumber = (landline, region) => {
  const parsedLandline = parse(landline, region.toUpperCase())
  if (isEmptyObject(parsedLandline)) return false

  const type = getNumberType(parsedLandline)

  return type === 'FIXED_LINE' || type === 'FIXED_LINE_OR_MOBILE'
}

export const isNullOrZero = value => !value && value !== 0

export const closeModalWithConfirmation = async (
  isDirty,
  confirmCloseModal,
  ...confirmProps
) => {
  if (isDirty) {
    confirm({
      title: 'Warning!',
      content: (
        <div>
          <p>Cancelling will discard any progress you have made.</p>
          <p>Are you sure you want to proceed?</p>
        </div>
      ),
      width: 400,
      onOk: async () => {
        await confirmCloseModal()
      },
      ...confirmProps,
    })
  } else {
    await confirmCloseModal()
  }
}

export const calculateTotal = (array, calculateField) =>
  (array || []).reduce(
    (accumulator, currentElement) =>
      accumulator + get(currentElement, calculateField, 0),
    0
  )

export const checkIsInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const isNullorUndefined = value => value === null || value === undefined

export const isEmptyValue = value => isNullorUndefined(value) || value === ''

export const clearSearchParams = ({ names, searchParams }) => {
  names?.forEach(name => {
    searchParams.delete(name)
  })

  return searchParams
}

export const validateDateIntegerNumber = async (_, value) => {
  const regex = /^(\d*)$/

  if (!value) return

  if (value <= 0) {
    throw new Error('Must be larger than 0')
  } else if (!regex.test(value)) {
    throw new Error('Only integers are allowed')
  }
}

export const getPerformerName = performer =>
  performer.alias ||
  [performer?.subject.firstname, performer?.subject.lastname].join(' ')

export const isDateBeforeToday = current =>
  current && current <= moment().endOf('day')
