import resettableMixin from 'flynk.app.web.core.data/stores/resettableMixin'
import set from 'lodash/set'
import { action, observable } from 'mobx'

import { INITIAL_MODAL_DATA, ModalMode } from '@/constants'

@resettableMixin
class ModalStateStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @observable modalsDataByKey = {}

  @action.bound
  initModal(modalKey) {
    const isAvailableModal = this.modalsDataByKey?.[modalKey]

    if (isAvailableModal) return

    set(this.modalsDataByKey, modalKey, INITIAL_MODAL_DATA)
  }

  @action.bound
  closeModal(modalKey) {
    set(this.modalsDataByKey, modalKey, INITIAL_MODAL_DATA)
  }

  @action.bound
  openModal(modalKey, modalData = { modalMode: ModalMode.Add }) {
    set(this.modalsDataByKey, modalKey, modalData)
  }
}

export default ModalStateStore
