export const EXCLUSIVENESS_TIME = 1000 * 60 * 60 * 2

export const PATH = {
  offerList: '/musician/offers',
}

export const OfferType = {
  Offer: 1,
  AvailabilityCheck: 2,
}
