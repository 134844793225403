import React, { createContext, useEffect, useMemo, useState } from 'react'
import { Prompt as PromptLib, useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

const DEFAULT_VALUE = { setIsUnsaved: () => {}, setMessage: () => {} }

export const GlobalPromptContext = createContext(DEFAULT_VALUE)

const DEFAULT_MESSAGE = 'Changes you made may not be saved'

const GlobalPromptProvider = ({ children }) => {
  const [isUnsaved, setIsUnsaved] = useState(false)
  const [message, setMessage] = useState(DEFAULT_MESSAGE)
  const history = useHistory()

  const value = useMemo(() => ({ setIsUnsaved, setMessage }), [isUnsaved])

  useEffect(() => {
    if (isUnsaved) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [isUnsaved])

  useEffect(() => {
    const unListenHistoryChanged = history.listen(() => {
      // Update your state or perform any action here
      setIsUnsaved(false)
      setMessage(DEFAULT_MESSAGE)
    })

    return () => {
      unListenHistoryChanged()
    }
  }, [history])

  return (
    <GlobalPromptContext.Provider value={value}>
      {children}
      <PromptLib when={isUnsaved} message={message} />
    </GlobalPromptContext.Provider>
  )
}

GlobalPromptProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlobalPromptProvider
