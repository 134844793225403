import { observer, PropTypes as MobxPropTypes } from 'mobx-react'
import React from 'react'

import { Typography } from 'antd'

const { Link } = Typography

const URLS = {
  BOSTON_URL: '/boston/RS-Privacy-Policy-Terms-of-Use-(Boston).pdf',
  SYDNEY_URL: '/sydney/RS-Privacy-Policy-Terms-of-Use-(Sydney).pdf',
  TORONTO_URL: '/toronto/RS-Privacy-Policy-Terms-of-Use-(Toronto).pdf',
}

const TermsAndConditions = ({ selectedCity }) => {
  let selectedCityTermsAndConditionsUrl

  const regionName = selectedCity.region?.name.toLowerCase()

  if (regionName === 'us') {
    selectedCityTermsAndConditionsUrl = URLS.BOSTON_URL
  }

  if (regionName === 'au') {
    selectedCityTermsAndConditionsUrl = URLS.SYDNEY_URL
  }

  if (regionName === 'ca') {
    selectedCityTermsAndConditionsUrl = URLS.TORONTO_URL
  }

  return (
    <Link
      href={selectedCityTermsAndConditionsUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="terms-conditions-link"
      download
    >
      Privacy Policy
    </Link>
  )
}

TermsAndConditions.propTypes = {
  selectedCity: MobxPropTypes.objectOrObservableObject,
}
TermsAndConditions.defaultProps = { selectedCity: {} }

export default observer(TermsAndConditions)
