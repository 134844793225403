import { observer } from 'mobx-react'
import React from 'react'

import { useStores } from 'flynk.app.web.core.components/helpers'
import PropTypes from 'prop-types'

import Footer from '../components/Footer'

const FooterContainer = ({ hasStickyNav }) => {
  const {
    bookingStore: { selectedCity, websiteUrl },
  } = useStores()

  return (
    <Footer
      hasStickyNav={hasStickyNav}
      selectedCity={selectedCity}
      websiteUrl={websiteUrl}
    />
  )
}

FooterContainer.propTypes = {
  hasStickyNav: PropTypes.bool,
}

FooterContainer.defaultProps = {
  hasStickyNav: false,
}

export default observer(FooterContainer)
