export const getFullAddressFormatted = address => {
  const result = []

  if (address.name && address.name.trim() !== '') {
    result.push(address.name)
  }

  if (address.line1 && address.line1.trim() !== '') {
    result.push(address.line1)
  }

  if (address.line2 && address.line2.trim() !== '') {
    result.push(address.line2)
  }

  let detail = ''

  if (address.city && address.city.trim() !== '') {
    detail += address.city
  }

  if (address.state && address.state.trim() !== '') {
    detail += ' '
    detail += address.state
  }

  if (address.code && address.code.trim() !== '') {
    detail += ' '
    detail += address.code
  }

  if (address.country && address.country.trim() !== '') {
    if (detail !== '') {
      detail += ', '
    }

    detail += address.country
  }

  if (detail.trim() !== '') {
    result.push(detail)
  }

  return result
}

export const getAddressSummary = address => {
  let result = ''

  if (address.name && address.name.trim() !== '') {
    result += address.name
  }

  if (address.line1 && address.line1.trim() !== '') {
    if (result !== '') {
      result += ', '
    }

    result += address.line1
  }

  if (address.line2 && address.line2.trim() !== '') {
    if (result !== '') {
      result += ', '
    }

    result += address.line2
  }

  let detail = ''

  if (address.city && address.city.trim() !== '') {
    detail += address.city
  }

  if (address.state && address.state.trim() !== '') {
    detail += ' '
    detail += address.state
  }

  if (address.code && address.code.trim() !== '') {
    detail += ' '
    detail += address.code
  }

  if (address.country && address.country.trim() !== '') {
    if (detail !== '') {
      detail += ', '
    }

    detail += address.country
  }

  if (detail.trim() !== '') {
    if (result !== '') {
      result += ', '
    }

    result += detail
  }

  return result
}

export const getAreaFromAddressComponents = (addressComponents, ...types) => {
  let result

  let i = 0

  while (!result && i < types.length) {
    result = addressComponents
      // eslint-disable-next-line no-loop-func
      .find(component => component?.types?.includes(types[i]))

    i += 1
  }

  return result
}
