import { Modal, notification } from 'antd'
import FileSaver from 'file-saver'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import { action, computed, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import moment from 'moment'

import { mergeDeep } from '@/common'
import {
  BookingCategory,
  BookingInvoiceTitle,
  BookingStatus,
  MOMENT_DATE_FORMAT,
  NO_PERFORMANCE,
  PaymentStatus,
  PerformanceType,
} from '@/constants'
import { TIME_FORMAT } from '@/flynk.app.web.core.components/constants'
import { getTicks } from '@/flynk.app.web.core.components/helpers'
import {
  ALERT_TIMER,
  DEFAULT_PAGINATION,
  RESPONSE_TYPES,
  SetDefaultPagination,
  USER_STATUSES,
} from '@/flynk.app.web.core.data/constants'
import resettableMixin from '@/flynk.app.web.core.data/stores/resettableMixin'
import {
  getAllStatuses,
  getBookingStatuses,
  setCorrectContentTypes,
} from '@/helpers/booking'
import { orderByCreatedDate } from '@/helpers/common'
import { formatName } from '@/helpers/user'

const BookingStatuses = getAllStatuses()

@resettableMixin
class BookingAdminStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @persist('list') @observable bookings = []

  @persist('list') @observable previousBookings = []

  @persist('list') @observable upcomingBookings = []

  @persist('object') @observable recentlyViewedBookings = {}

  @persist('object') @observable booking = {}

  @observable tempBooking = {}

  @persist('list') @observable eventGroups = []

  @persist('list') @observable packages = []

  @persist('list') @observable ceremonyPerformances = []

  @persist('list') @observable receptionPerformances = []

  @persist('list') @observable performers = []

  @persist('list') @observable bookingPerformers = []

  @observable bookedPerformersExist = false

  @persist('list') @observable performanceTypes = []

  @persist('list') @observable performancePositions = []

  @persist('list') @observable musicians = []

  @persist('list') @observable musiciansByName = []

  @persist('object') @observable musiciansByNameCache = {}

  @observable pagination = DEFAULT_PAGINATION

  @observable upcomingPagination = DEFAULT_PAGINATION

  @observable previousPagination = DEFAULT_PAGINATION

  @observable getBookingsByCityLoading = false

  @observable getBookingByIdLoading = false

  @observable editBookingModalVisible = false

  @observable deleteBookingLoading = false

  @observable updateBookingLoading = false

  @observable showAllBookings = false

  @observable sendOffersToMusicianLoading = false

  @observable getPerformancePositionsLoading = false

  @observable getCityMusiciansBySkillLoading = false

  @observable assignMusicianToPositionLoading = false

  @observable savePerformancesPositionTimeLoading = false

  @observable getMusiciansLoading = false

  @observable confirmBookedMusiciansLoading = false

  @persist('list') @observable salesManagers = []

  @persist('object') @observable quotePrice = {}

  @persist('object') @observable firmPrice = {}

  @observable updateInvoiceLoading = false

  @observable updateQuotePriceLoading = false

  @observable getFirmPricesByBookingIdLoading = false

  @observable updateFirmPriceLoading = false

  @observable checkAvailabilityByBookingIdLoading = false

  @observable reserveSingerLoading = false

  @observable cancelSingerJobLoading = false

  @observable cancelSingerReservationLoading = false

  @observable assignSalesManagerLoading = false

  @observable getInvoicesByBookingIdLoading = false

  @observable getPerformersByBookingIdLoading = false

  @observable getOfferHistoryByPositionIdLoading = false

  @persist('object') @observable depositPayments = {}

  @observable isPaymentRequestModalOpened = false

  @observable getDepositPaymentsByBookingIdLoading = false

  @observable sendDepositPaymentsLoading = false

  @observable completeDepositPaymentsLoading = false

  @persist('object') @observable fullPayments = {}

  @observable getFullPaymentsByBookingIdLoading = false

  @observable sendFullPaymentsLoading = false

  @observable completeFullPaymentsLoading = false

  @observable sendFullPaymentRemindLoading = false

  @observable sendDepositPaymentRemindLoading = false

  @persist('object') @observable eventTimeline = []

  @persist('object') @observable eventTimelineId = null

  @observable createEventTimelineLoading = false

  @persist('object') @observable gigs = []

  @persist('object') @observable keySongsFiles = {}

  @observable uploadSongUrl = null

  @observable getUploadSongUrlLoading = false

  @observable getUploadEventTimelineUrlLoading = false

  @observable sendKeySongLoading = false

  @observable deleteKeySongLoading = false

  @observable sendPlaylistLoading = false

  @observable deletePlaylistLoading = false

  @observable sendTopSongLoading = false

  @observable deleteTopSongLoading = false

  @observable getQuotePricesByBookingIdLoading = false

  @persist('list') @observable bookingPaymentStatuses = []

  @observable bookingPaymentStatusesLoading = false

  @persist('list') @observable musicianPayments = []

  @persist('list') @observable musicianPayment = []

  @observable musicianPaymentsLoading = false

  @observable getMusicianPaymentByIdLoading = false

  @observable isGigListPaymentModalOpened = false

  @observable gigListPaymentModalUserId = ''

  @observable downloadBookingSheetLoading = false

  @persist @observable showCancelRecords = false

  @observable allocateSingers = []

  @observable isConfirmedAllocateSingers = false

  @computed get latestBookingPaymentStatus() {
    return this.bookingPaymentStatuses[this.bookingPaymentStatuses.length - 1]
  }

  @computed get selectedBooking() {
    return this.bookings.find(booking => booking.id === this.booking.id) || {}
  }

  @computed get joinedUpcomingAndPreviousBookings() {
    return [...this.upcomingBookings, ...this.previousBookings]
  }

  @computed get bookingStatusIds() {
    return getBookingStatuses(this.showAllBookings).map(status => status.id)
  }

  @computed get isBookingManagementAllowed() {
    return !!(
      this.rootStore.profileStore.isAdmin ||
      (this.rootStore.profileStore.isSalesManager &&
        this.rootStore.profileStore.userId ===
          this.booking?.customer?.assingeeUserId)
    )
  }

  @computed get ceremonyType() {
    return this.performanceTypes && this.performanceTypes.length > 0
      ? this.performanceTypes.find(
          type => type.name === PerformanceType.Ceremony
        )
      : {}
  }

  @computed get receptionType() {
    return this.performanceTypes && this.performanceTypes.length > 0
      ? this.performanceTypes.find(
          type => type.name === PerformanceType.Reception
        )
      : {}
  }

  @computed get ceremonyTypeId() {
    return this.ceremonyType.id || ''
  }

  @computed get receptionTypeId() {
    return this.receptionType.id || ''
  }

  @computed get depositInvoiceId() {
    return get(this.depositPayments, 'pendingInvoice.id', '')
  }

  @computed get depositPaymentId() {
    const depositPayment = get(this.depositPayments, 'payments', []).find(
      p => p.status === PaymentStatus.InProgress
    )

    return depositPayment ? depositPayment.id : ''
  }

  @computed get fullInvoiceId() {
    return get(this.fullPayments, 'pendingInvoice.id', '')
  }

  @computed get fullPaymentId() {
    const fullPayment = get(this.fullPayments, 'payments', []).find(
      p => p.status === PaymentStatus.InProgress
    )

    return fullPayment ? fullPayment.id : ''
  }

  @computed get isPlanningStatus() {
    return this.booking?.status === BookingStatus.Planning.id
  }

  @computed get sendOffersDisabled() {
    return !!(
      this.depositInvoiceId ||
      this.depositPaymentId ||
      this.fullInvoiceId
    )
  }

  @computed get remainingPaymentAmount() {
    const paymentAmount = [
      ...(this.depositPayments?.payments || []),
      ...(this.fullPayments?.payments || []),
    ].reduce((acc, payment) => payment?.amount + acc, 0)

    return this.firmPrice?.payable - paymentAmount
  }

  @computed get isLeadMusician() {
    return (this.musicians || []).some(
      mus =>
        mus.isLead &&
        mus.musicianId === this.rootStore.profileStore?.profile.user.id
    )
  }

  @observable bookingListUpcoming = true

  @action.bound
  toggleBookingListUpcoming = bool => {
    this.bookingListUpcoming = bool
  }

  @action.bound
  getCeremonyVenue(venues) {
    const ceremonyVenue = venues.find(
      venue => venue.performanceTypeId === this.ceremonyTypeId
    )

    return ceremonyVenue || venues[0]
  }

  @action.bound
  getReceptionVenue(venues) {
    const receptionVenue = venues.find(
      venue => venue.performanceTypeId === this.receptionTypeId
    )

    return receptionVenue || venues[1]
  }

  @action.bound
  getFirstVenue(venues) {
    const ceremonyVenue = this.getCeremonyVenue(venues)
    const receptionVenue = this.getReceptionVenue(venues)

    if (ceremonyVenue.formattedAddress) {
      return ceremonyVenue
    }

    if (receptionVenue.formattedAddress) {
      return receptionVenue
    }

    return {}
  }

  @action.bound
  async getBookingsByCity(
    data = {
      skip: 0,
      take: this.pagination.pageSize,
    },
    statuses = this.bookingStatusIds
  ) {
    this.getBookingsByCityLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.getBookingsByCity({
        ...data,
        cityIds: JSON.stringify(this.rootStore.cityStore.selectedCityIds),
        status: JSON.stringify(statuses),
      })

      if (res.payload && res.payload.items) {
        runInAction(() => {
          this.bookings = res.payload.items
          this.pagination = SetDefaultPagination(this.pagination, res)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getBookingsByCityLoading = false
    }
  }

  @action.bound
  async updateRecentlyViewedBookings(items) {
    const { selectedCityId } = this.rootStore.cityStore
    const viewedBookings =
      (this.recentlyViewedBookings[selectedCityId] && [
        ...this.recentlyViewedBookings[selectedCityId],
      ]) ||
      []

    if (viewedBookings) {
      this.recentlyViewedBookings = {
        ...this.recentlyViewedBookings,
        [selectedCityId]: viewedBookings.map(recentBooking => {
          const updatedRecent = items.find(item => item.id === recentBooking.id)

          if (updatedRecent) {
            return updatedRecent
          }

          return recentBooking
        }),
      }
    }
  }

  @action.bound
  async getUpcomingBookingsByCity(
    data = {
      skip: 0,
      take: this.pagination.pageSize,
    },
    statuses = this.bookingStatusIds
  ) {
    this.getBookingsByCityLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.getUpcomingBookings({
        ...data,
        cityIds: JSON.stringify(this.rootStore.cityStore.selectedCityIds),
        status: JSON.stringify(statuses),
        viewDate: moment().format(MOMENT_DATE_FORMAT),
      })

      if (res.payload && res.payload.items) {
        runInAction(() => {
          this.upcomingBookings = res.payload.items
          this.upcomingPagination = SetDefaultPagination(
            this.upcomingPagination,
            res
          )
          this.updateRecentlyViewedBookings(res.payload.items)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getBookingsByCityLoading = false
    }
  }

  @action.bound
  async getPreviousBookingsByCity(
    data = {
      skip: 0,
      take: this.pagination.pageSize,
    },
    statuses = this.bookingStatusIds
  ) {
    this.getBookingsByCityLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.getPreviousBookings({
        ...data,
        cityIds: JSON.stringify(this.rootStore.cityStore.selectedCityIds),
        status: JSON.stringify(statuses),
        viewDate: moment().format(MOMENT_DATE_FORMAT),
      })

      if (res.payload && res.payload.items) {
        runInAction(() => {
          this.previousBookings = res.payload.items
          this.previousPagination = SetDefaultPagination(
            this.previousPagination,
            res
          )
          this.updateRecentlyViewedBookings(res.payload.items)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getBookingsByCityLoading = false
    }
  }

  @action.bound
  toggleShowAllBookings = async ({ show }) => {
    this.showAllBookings = show
    await this.getBookingsByCity()
  }

  @action.bound
  toggleBookedPerformersExist = bool => {
    this.bookedPerformersExist = bool
  }

  @action.bound
  clearBookings = () => {
    this.bookings = []
  }

  @action.bound
  async getBookingById(id = this.booking.id) {
    this.getBookingByIdLoading = true
    let res

    try {
      res = await this.rootAPI.bookingAdminAPI.getBookingById(id)

      runInAction(() => {
        if (!this.rootStore.profileStore.isMusician) {
          this.getEventTimelineTemplate()
        }

        let recentBooking
        let recentlyViewedBookings
        const { selectedCityId } = this.rootStore.cityStore
        const viewedBookings =
          (this.recentlyViewedBookings[selectedCityId] && [
            ...this.recentlyViewedBookings[selectedCityId],
          ]) ||
          []

        if (!viewedBookings) {
          const newSelectedBooking =
            this.joinedUpcomingAndPreviousBookings.find(b => b.id === id)

          if (newSelectedBooking) {
            recentlyViewedBookings = {
              ...this.recentlyViewedBookings,
              [selectedCityId]: [newSelectedBooking],
            }
          }
        } else {
          const indexOf = viewedBookings.findIndex(booking => booking.id === id)

          const isAlreadyInRecent = indexOf !== -1

          if (isAlreadyInRecent) {
            ;[recentBooking] = viewedBookings.splice(indexOf, 1)

            if (recentBooking) {
              recentlyViewedBookings = {
                ...this.recentlyViewedBookings,
                [selectedCityId]: [recentBooking, ...viewedBookings],
              }
            }
          } else {
            const newSelectedBooking =
              this.joinedUpcomingAndPreviousBookings.find(b => b.id === id)

            if (newSelectedBooking) {
              recentlyViewedBookings = {
                ...this.recentlyViewedBookings,
                [selectedCityId]: [
                  newSelectedBooking,
                  ...this.recentlyViewedBookings[selectedCityId],
                ].slice(0, 4),
              }
            }
          }
        }

        this.recentlyViewedBookings = { ...recentlyViewedBookings }
        const { payload } = res
        this.booking = payload
        this.gigs =
          payload.event && payload.event.gigs ? payload.event.gigs : []
        this.bookedPerformersExist = false
        this.rootStore.cityStore.selectCities([payload.cityId])
        this.updateBookingRecentlyViewStatus(this.booking.id, payload?.status)
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getBookingByIdLoading = false
    }

    return res
  }

  @action.bound
  setBooking(booking) {
    this.booking = booking
  }

  @action.bound
  clearBooking() {
    this.booking = {}
  }

  @action.bound
  clearPreviousBookings() {
    this.previousBookings = []
  }

  @action.bound
  clearUpcomingBookings() {
    this.upcomingBookings = []
  }

  @action.bound
  async updateBookingStatus(booking, status) {
    let isSuccess = false

    const {
      cityStore: { selectedCityId },
    } = this.rootStore

    const oldBookingStatus =
      BookingStatuses.find(s => s.id === Number(booking.status)) || {}
    const newBookingStatus =
      BookingStatuses.find(s => s.id === Number(status)) || {}

    try {
      const res = await this.rootAPI.bookingAdminAPI.updateBookingStatus(
        booking.id,
        status
      )
      const shouldKeepCanceledRecord =
        BookingStatus.Cancelled.id === Number(status) && this.showCancelRecords

      if (res) {
        if (this.booking.id === booking.id) {
          if (
            this.bookingStatusIds.includes(Number(status)) ||
            shouldKeepCanceledRecord
          ) {
            this.booking = {
              ...this.booking,
              status: Number(status),
            }
          } else {
            this.clearBooking()
          }
        }

        this.bookings = this.bookings
          .map(b => ({
            ...b,
            status: b.id === booking.id ? Number(status) : b.status,
          }))
          .filter(
            b =>
              !(
                b.id === booking.id &&
                !this.bookingStatusIds.includes(Number(b.status))
              )
          )

        this.recentlyViewedBookings = {
          ...this.recentlyViewedBookings,
          [selectedCityId]: this.recentlyViewedBookings[selectedCityId].filter(
            b =>
              !(
                b.id === booking.id &&
                !this.bookingStatusIds.includes(Number(status))
              )
          ),
        }

        await this.getPreviousAndUpcomingBookings()

        isSuccess = true
        notification.success({
          message: 'Success!',
          description: `Updated booking status from ${oldBookingStatus.name} to ${newBookingStatus.name}.`,
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      notification.error({
        message: 'Failed!',
        description: `${(err && err.description) || 'Update status failed. Please try again.'}`,
        placement: 'bottomRight',
      })
    }

    return { isSuccess }
  }

  @action.bound
  toggleEditBookingModal({ visible }) {
    this.editBookingModalVisible = visible

    if (!visible && this.tempBooking.id) {
      this.clearTempBooking()
    }
  }

  @action.bound
  async selectBooking(id) {
    if (this.booking.id === id) {
      this.tempBooking = this.booking
    } else {
      await this.getTempBookingById(id)
    }
  }

  @action.bound
  async getTempBookingById(id) {
    try {
      const res = await this.rootAPI.bookingAdminAPI.getBookingById(id)

      runInAction(() => {
        this.tempBooking = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  clearTempBooking() {
    this.tempBooking = {}
  }

  @action.bound
  async deleteBooking(id) {
    this.deleteBookingLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.deleteBooking(id)

      if (res) {
        this.bookings = this.bookings.filter(booking => booking.id !== id)
        this.rootStore.alertStore.success({
          title: 'The booking has been deleted successfully!',
          timer: ALERT_TIMER,
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.deleteBookingLoading = false
    }
  }

  @action.bound
  async updateBooking(
    data,
    options = {
      invoice: false,
      availability: false,
      reloadBooking: true,
      reloadBookingList: true,
      singer: false,
      reloadPerformancePosition: false,
    },
    message = {
      type: 'alert',
      success: 'The booking has been updated successfully!',
      fail: '',
    },
    force = false
  ) {
    let success = false
    this.updateBookingLoading = true
    let sendData = { ...data }

    if (data?.eventDateInfo?.eventDate) {
      sendData = {
        ...data,
        eventDateInfo: {
          ...data.eventDateInfo,
          eventDate: `${moment(data.eventDateInfo.eventDate).format(
            MOMENT_DATE_FORMAT
          )}`,
        },
      }
    }

    const currentBooking = this.booking

    try {
      const { customerInfos } = sendData

      if (customerInfos && customerInfos.length) {
        const customerContacts = customerInfos.map(contact => {
          const updatedContact = this.booking.customerContacts.find(
            el => el.id === contact.id
          )

          if (updatedContact) {
            return {
              ...updatedContact,
              region: contact.region.toUpperCase(),
              mobile: contact.mobile,
              name: contact.name,
              relationship: contact.relationship,
            }
          }

          return contact
        })

        const newBooking = {
          ...this.booking,
          customerContacts,
        }

        const isChanged = !isEqual(newBooking, this.booking)

        if (isChanged) {
          runInAction(() => {
            this.booking = newBooking
          })
        } else {
          return true
        }
      }

      const res = await this.rootAPI.bookingAdminAPI.updateBooking(
        this.booking.id,
        sendData,
        force
      )

      if (res.type === RESPONSE_TYPES.WARNING) {
        Modal.confirm({
          title: res.message,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onCancel: async () => {
            runInAction(() => {
              this.booking = currentBooking
            })
          },
          onOk: async () => {
            await this.updateBooking(data, options, message, true)
          },
        })

        return
      }

      if (res) {
        const tasks = []

        if (options.reloadBookingList) {
          tasks.push(this.getPreviousAndUpcomingBookings())
        }

        if (options.reloadBooking) {
          tasks.push(this.getBookingById(this.booking.id))
        }

        if (options.invoice) {
          tasks.push(this.getQuotePricesByBookingId(this.booking.id))
          tasks.push(this.getFirmPricesByBookingId(this.booking.id))
          tasks.push(this.getDepositPaymentsByBookingId(this.booking.id))
          tasks.push(this.getFullPaymentsByBookingId(this.booking.id))
          tasks.push(this.getBookingPaymentStatuses(this.booking.id))
        }

        if (options.singer) {
          tasks.push(this.getPerformersByBookingId(this.booking.id))
        }

        if (options.reloadPerformancePosition) {
          tasks.push(this.getPerformancePositionsByBookingId(this.booking.id))
          tasks.push(this.getPerformers())
        }

        await Promise.all(tasks)

        const msg = message.success

        if (message.type === 'notification') {
          notification.success({
            message: 'Success!',
            description: msg,
            placement: 'bottomRight',
          })
        } else {
          this.rootStore.alertStore.success({
            title: msg,
            timer: ALERT_TIMER,
          })
        }

        success = true
      }

      if (
        this.bookingPerformers?.length &&
        this.isPlanningStatus &&
        (data?.eventDateInfo?.eventDate || data?.gigUpdateInfo?.gigs)
      ) {
        Modal.info({
          title: 'Notice!',
          content:
            'As the gig details have been changed, please check Singer Availability again',
        })
      }
    } catch (err) {
      const error =
        message && message.fail ? { description: message.fail } : err
      this.rootStore.errorsStore.addError(error)
      runInAction(() => {
        this.booking = currentBooking
      })
    } finally {
      this.updateBookingLoading = false
    }

    return success
  }

  // Packages
  @action.bound
  async getPackages() {
    try {
      const eventGroupRes = await this.rootAPI.bookingAPI.getEventGroups({
        cityId: this.rootStore.cityStore.selectedCityId,
      })

      if (eventGroupRes && eventGroupRes.payload) {
        this.eventGroups = eventGroupRes.payload.sort(
          (a, b) => a.order - b.order
        )

        const res = await this.rootAPI.bookingAPI.getAvailablePackages({
          cityId: this.rootStore.cityStore.selectedCityId,
        })

        if (res && res.payload) {
          this.packages = res.payload.map(pack => ({
            ...pack,
            group: this.eventGroups.find(
              group => group.id === pack.groupId
            ) || { name: '' },
          }))
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getPerformanceTypes() {
    try {
      if (this.performanceTypes.length === 0) {
        const performanceTypesResponse =
          await this.rootAPI.performanceAPI.getPerformanceTypes()

        if (performanceTypesResponse && performanceTypesResponse.payload) {
          this.performanceTypes = performanceTypesResponse.payload
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @observable getPerformancesLoading = false

  @action.bound
  async getPerformances() {
    this.getPerformancesLoading = true

    try {
      if (this.performanceTypes.length === 0) {
        await this.getPerformanceTypes()
      }

      const promises = [
        this.rootAPI.bookingAPI.getPerformances(
          {
            performanceTypeId: this.ceremonyTypeId,
            cityId: this.rootStore.cityStore.selectedCityId,
            duration: 1,
          },
          []
        ),
        this.rootAPI.bookingAPI.getPerformances(
          {
            performanceTypeId: this.receptionTypeId,
            cityId: this.rootStore.cityStore.selectedCityId,
            duration: 5,
          },
          []
        ),
      ]

      const [ceremonyRes, receptionRes] = await Promise.all(promises)

      runInAction(() => {
        this.ceremonyPerformances = [
          {
            id: NO_PERFORMANCE.Ceremony.value,
            name: NO_PERFORMANCE.Ceremony.label,
            disabled: true,
          },
          ...ceremonyRes.payload,
        ]
        this.receptionPerformances = [
          {
            id: NO_PERFORMANCE.Reception.value,
            name: NO_PERFORMANCE.Reception.label,
            disabled: true,
          },
          ...receptionRes.payload,
        ]
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformancesLoading = false
    }
  }

  // Performers
  @action.bound
  async getPerformers() {
    let res = null

    try {
      res = await this.rootAPI.bookingAdminAPI.getPerformers({
        bookingId: this.booking.id,
        skip: 0,
        take: 100,
      })

      if (res?.payload?.items?.length) {
        const sortedRes = res.payload.items.sort((a, b) => a.order - b.order)
        this.performers = [
          {
            id: 'choose-for-me',
            photo: '/images/choose-for-me.jpg',
            subject: {
              firstname: 'Choose For Me',
            },
          },
          ...sortedRes,
        ]
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getPerformersByBookingId(id) {
    this.getPerformersByBookingIdLoading = true

    try {
      const res = await this.rootAPI.performerAPI.getPerformersByBookingId(id)

      if (res?.payload && Array.isArray(res?.payload)) {
        this.bookingPerformers = res.payload.map(performer => ({
          ...performer,
          gigOffers: orderByCreatedDate(performer?.gigOffers),
        }))
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformersByBookingIdLoading = false
    }
  }

  @action.bound
  clearBookingPerformers() {
    this.bookingPerformers = []
  }

  // Sales Managers
  @action.bound
  async getSalesManagersByCityId() {
    if (this.rootStore.cityStore.selectedCityId) {
      try {
        const res = await this.rootAPI.prospectAPI.getSalesManagersByCityId(
          this.rootStore.cityStore.selectedCityId,
          USER_STATUSES.Disabled
        )

        if (res && res.payload) {
          runInAction(() => {
            this.salesManagers = res.payload.map(salesManager => {
              const status = get(
                salesManager,
                'roles[0].status',
                USER_STATUSES.Error
              )

              return {
                ...salesManager.user,
                status,
                active: status > USER_STATUSES.Disabled,
              }
            })
          })
        }
      } catch (err) {
        this.rootStore.errorsStore.addError(err)
      }
    }
  }

  @action.bound
  async assignSalesManager(salesManager, customer) {
    this.assignSalesManagerLoading = true

    try {
      if (customer.id) {
        const res = await this.rootAPI.prospectAPI.assignSalesManager(
          salesManager.id,
          { customerIds: [customer.id] }
        )

        if (res) {
          await this.getBookingById(this.booking.id)
          notification.success({
            message: 'Success!',
            description: `Assigned customer "${formatName(customer)}" for sales manager "${formatName(salesManager)}"!`,
            placement: 'bottomRight',
          })
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.assignSalesManagerLoading = false
    }
  }

  // Invoices
  @action.bound
  async getQuotePricesByBookingId(id) {
    this.getQuotePricesByBookingIdLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.getQuotePricesByBookingId(id)

      runInAction(() => {
        this.quotePrice = res.payload
      })
    } catch (err) {
      if (err.type === -1) {
        this.quotePrice = {}
      } else {
        this.rootStore.errorsStore.addError(err)
      }
    } finally {
      this.getQuotePricesByBookingIdLoading = false
    }
  }

  @action.bound
  async getFirmPricesByBookingId(id) {
    this.getFirmPricesByBookingIdLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.getFirmPricesByBookingId(id)

      runInAction(() => {
        this.firmPrice = {
          ...res.payload,
          items: res.payload.items.sort((a, b) => {
            if (
              a?.title === BookingInvoiceTitle.Package ||
              b?.title === BookingInvoiceTitle.SingerSurcharge
            ) {
              return -1
            }

            if (
              b?.title === BookingInvoiceTitle.Package ||
              a?.title === BookingInvoiceTitle.SingerSurcharge
            ) {
              return 1
            }

            return a?.title?.localeCompare(b?.title)
          }),
        }
      })
    } catch (err) {
      if (err.type === -1) {
        this.firmPrice = {}
      } else {
        this.rootStore.errorsStore.addError(err)
      }
    } finally {
      this.getFirmPricesByBookingIdLoading = false
    }
  }

  @action.bound
  async updateQuotePrice(data) {
    let success = false
    this.updateQuotePriceLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.updateQuotePrice(
        this.booking.id,
        data
      )

      if (res) {
        const tasks = [
          this.getPreviousAndUpcomingBookings(),
          this.getQuotePricesByBookingId(this.booking.id),
          this.getFirmPricesByBookingId(this.booking.id),
          this.getDepositPaymentsByBookingId(this.booking.id),
          this.getFullPaymentsByBookingId(this.booking.id),
          this.getBookingPaymentStatuses(this.booking.id),
        ]

        await Promise.all(tasks)

        this.rootStore.alertStore.success({
          title: 'The adjustment has been added successfully!',
          timer: ALERT_TIMER,
        })
        success = true
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateQuotePriceLoading = false
    }

    return success
  }

  @observable getPreviousAndUpcomingBookingsLoading = false

  @action.bound
  async getPreviousAndUpcomingBookings() {
    this.getPreviousAndUpcomingBookingsLoading = true
    const { current, currentPage, pageSize } = this.upcomingPagination
    const {
      current: prevCurrent,
      currentPage: prevCurrentPage,
      pageSize: prevPageSize,
    } = this.previousPagination
    const take = pageSize
    const skip = ((current || currentPage) - 1) * pageSize
    const prevSkip = ((prevCurrent || prevCurrentPage) - 1) * prevPageSize

    const availableStatus = Array.from(this.bookingStatusIds)

    if (this.showCancelRecords) {
      availableStatus.push(BookingStatus.Cancelled.id)
    }

    const tasks = [
      this.getUpcomingBookingsByCity(
        {
          skip,
          take,
        },
        availableStatus
      ),
      this.getPreviousBookingsByCity(
        {
          skip: prevSkip,
          take: prevPageSize,
        },
        availableStatus
      ),
    ]
    await Promise.all(tasks)
    this.getPreviousAndUpcomingBookingsLoading = false
  }

  @action.bound
  toggleIsGigListPaymentModalOpened() {
    this.isGigListPaymentModalOpened = !this.isGigListPaymentModalOpened
  }

  @action.bound
  setGigListPaymentModalUserId(id) {
    this.gigListPaymentModalUserId = id
  }

  @action.bound
  async getMusicianPayments(bookingId = this.booking.id) {
    this.musicianPaymentsLoading = true

    try {
      const { payload } =
        await this.rootAPI.bookingAdminAPI.getMusicianPayments(bookingId)

      if (payload) {
        runInAction(() => {
          this.musicianPayments = (payload || []).map(item => ({
            ...item,
            invoice: {
              ...item?.invoice,
              items: item?.invoice?.items?.sort((a, b) => {
                if (
                  a?.category === BookingCategory.FirmPrice ||
                  b?.category === BookingCategory.Surcharge
                ) {
                  return -1
                }

                if (
                  b?.category === BookingCategory.FirmPrice ||
                  a?.category === BookingCategory.Surcharge
                ) {
                  return 1
                }

                return a?.title?.localeCompare(b?.invoiceRef)
              }),
            },
          }))
        })
      }
    } catch (err) {
      if (err.type === -1) {
        this.musicianPayments = []
      } else {
        this.rootStore.errorsStore.addError(err)
      }
    }

    this.musicianPaymentsLoading = false
  }

  @action.bound
  async getMusicianPaymentById(musicianId) {
    this.getMusicianPaymentByIdLoading = true

    try {
      const bookingId = this.booking.id

      const { payload } =
        await this.rootAPI.bookingAdminAPI.getMusicianPaymentById(
          bookingId,
          musicianId
        )

      if (payload) {
        runInAction(() => {
          this.musicianPayment = payload
        })
      }
    } catch (err) {
      if (err.type === -1) {
        this.musicianPayment = []
      } else {
        this.rootStore.errorsStore.addError(err)
      }
    }

    this.getMusicianPaymentByIdLoading = false
  }

  @action.bound
  clearMusicianPayment() {
    this.musicianPayment = []
  }

  @action.bound
  async createMusicianPayments(
    invoiceId,
    data,
    message = {
      description: 'The adjustment has been added successfully!',
    }
  ) {
    this.musicianPaymentsLoading = true
    let success = false

    try {
      await this.rootAPI.bookingAdminAPI.createMusicianPayments(invoiceId, data)

      notification.success({
        message: 'Success!',
        description: message.description,
        placement: 'bottomRight',
      })
      success = true

      await this.getMusicianPayments(this.booking.id)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    this.musicianPaymentsLoading = false

    return success
  }

  @observable confirmAllPaymentLoading = false

  @action.bound
  async confirmAllPayments(bookingId) {
    this.confirmAllPaymentLoading = true
    let success = false

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.confirmAllPayments(bookingId)

      if (res) {
        this.rootStore.alertStore.success({
          title: 'Confirm payments successfully!',
          timer: ALERT_TIMER,
        })

        await Promise.all([
          this.getMusicianPayments(),
          this.getBookingById(bookingId),
        ])
        success = true
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    this.confirmAllPaymentLoading = false

    return success
  }

  @observable firmPriceAdjustmentModalVisible = false

  @observable firmPriceSelectedItem = {}

  @action.bound
  toggleFirmPriceAdjustmentModalVisible(selectedItem = {}) {
    this.firmPriceAdjustmentModalVisible = !this.firmPriceAdjustmentModalVisible
    this.firmPriceSelectedItem = selectedItem
  }

  @action.bound
  async updateFirmPrice(data) {
    let success = false
    this.updateFirmPriceLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.updateFirmPrice(
        this.booking.id,
        data
      )

      if (res) {
        const tasks = [
          this.getPreviousAndUpcomingBookings(),
          this.getQuotePricesByBookingId(this.booking.id),
          this.getFirmPricesByBookingId(this.booking.id),
          this.getDepositPaymentsByBookingId(this.booking.id),
          this.getFullPaymentsByBookingId(this.booking.id),
          this.getBookingPaymentStatuses(this.booking.id),
        ]

        await Promise.all(tasks)
        success = true
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err, { zIndex: 1003 })
    } finally {
      this.updateFirmPriceLoading = false
    }

    return success
  }

  // Availability
  @action.bound
  async checkAvailabilityByBookingId() {
    let isSuccess = false
    this.checkAvailabilityByBookingIdLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.checkAvailabilityByBookingId(
          this.booking.id
        )

      if (res) {
        await this.getPerformersByBookingId(this.booking.id)

        notification.success({
          message: 'Success!',
          description: 'Checked!',
          placement: 'bottomRight',
        })
        isSuccess = true
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.checkAvailabilityByBookingIdLoading = false
    }

    return isSuccess
  }

  @action.bound
  async reserveSinger(performerId) {
    let isSuccess = false
    this.reserveSingerLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.reserveSinger(
        performerId,
        this.booking.eventId
      )

      if (res) {
        isSuccess = true

        notification.success({
          message: 'Success!',
          description: 'Singer Reserved!',
          placement: 'bottomRight',
        })

        await Promise.allSettled([
          this.getPerformersByBookingId(this.booking.id),
          this.getFirmPricesByBookingId(this.booking.id),
          this.getDepositPaymentsByBookingId(),
          this.getFullPaymentsByBookingId(),
        ])
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.reserveSingerLoading = false
    }

    return isSuccess
  }

  @action.bound
  async cancelSingerReservation(performerId) {
    let isSuccess = false
    this.cancelSingerReservationLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.cancelSingerReservation(
        performerId,
        this.booking.eventId
      )

      if (res) {
        isSuccess = true

        notification.success({
          message: 'Success!',
          description: 'Singer Reservation canceled!',
          placement: 'bottomRight',
        })

        await Promise.allSettled([
          this.getPerformersByBookingId(this.booking.id),
          this.getFirmPricesByBookingId(this.booking.id),
          this.getDepositPaymentsByBookingId(),
          this.getFullPaymentsByBookingId(),
        ])
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.cancelSingerReservationLoading = false
    }

    return isSuccess
  }

  @action.bound
  async cancelSingerJob(performerId) {
    let isSuccess = false
    this.cancelSingerJobLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.cancelSingerJob(
        performerId,
        this.booking.eventId
      )

      if (res) {
        isSuccess = true

        notification.success({
          message: 'Success!',
          description: 'Singer Job canceled!',
          placement: 'bottomRight',
        })
        await Promise.all([
          this.getPerformersByBookingId(this.booking.id),
          this.getMusiciansByBookingId(this.booking.id),
          this.getPerformancePositionsByBookingId(this.booking.id),
        ])
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.cancelSingerJobLoading = false
    }

    return isSuccess
  }

  // Payments
  @action.bound
  async getDepositPaymentsByBookingId(id = this.booking.id) {
    this.getDepositPaymentsByBookingIdLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.getDepositPaymentsByBookingId(id)

      if (res && res.payload) {
        this.depositPayments = res.payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getDepositPaymentsByBookingIdLoading = false
    }
  }

  @action.bound
  async togglePaymentRequestModal(bool) {
    this.isPaymentRequestModalOpened = bool
  }

  @action.bound
  async sendDepositPayments(data) {
    this.sendDepositPaymentsLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.sendDepositPayments(data)

      if (res && res.payload) {
        const tasks = [
          this.getDepositPaymentsByBookingId(),
          this.getFullPaymentsByBookingId(),
          this.getBookingPaymentStatuses(this.booking.id),
          this.getPerformersByBookingId(this.booking.id),
          this.getPerformancePositionsByBookingId(this.booking.id),
        ]

        await Promise.all(tasks)

        notification.success({
          message: 'Success!',
          description: 'Request sent!',
          placement: 'bottomRight',
        })
        this.toggleDepositPaymentsModal(false)
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendDepositPaymentsLoading = false
    }
  }

  @action.bound
  async completeDepositPayments() {
    this.completeDepositPaymentsLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.completeDepositPaymentsByPaymentId(
          this.depositPaymentId
        )

      if (res) {
        const tasks = [
          await this.getPreviousAndUpcomingBookings(),
          this.getBookingById(this.booking.id),
          this.getDepositPaymentsByBookingId(),
          this.getFullPaymentsByBookingId(),
          this.getBookingPaymentStatuses(this.booking.id),
          this.getPerformersByBookingId(this.booking.id),
          this.getPerformancePositionsByBookingId(this.booking.id),
        ]

        await Promise.all(tasks)

        notification.success({
          message: 'Success!',
          description: 'Deposit Payment confirmed!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.completeDepositPaymentsLoading = false
    }
  }

  @action.bound
  clearDepositPayments() {
    this.depositPayments = {}
  }

  @action.bound
  async getFullPaymentsByBookingId(id = this.booking.id) {
    this.getFullPaymentsByBookingIdLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.getFullPaymentsByBookingId(id)

      if (res && res.payload) {
        this.fullPayments = res.payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getFullPaymentsByBookingIdLoading = false
    }
  }

  @action.bound
  async sendFullPayments(data) {
    this.sendFullPaymentsLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.sendFullPayments(data)

      if (res && res.payload) {
        const tasks = [
          this.getDepositPaymentsByBookingId(),
          this.getFullPaymentsByBookingId(),
          this.getBookingPaymentStatuses(this.booking.id),
          this.getPerformersByBookingId(this.booking.id),
          this.getPerformancePositionsByBookingId(this.booking.id),
        ]

        await Promise.all(tasks)

        notification.success({
          message: 'Success!',
          description: 'Invoice sent!',
          placement: 'bottomRight',
        })
        this.toggleDepositPaymentsModal(false)
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendFullPaymentsLoading = false
    }
  }

  @action.bound
  async completeFullPayments() {
    this.completeFullPaymentsLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.completeFullPaymentsByPaymentId(
          this.fullPaymentId
        )

      if (res) {
        const tasks = [
          this.getBookingById(this.booking.id),
          this.getFullPaymentsByBookingId(),
          this.getPerformersByBookingId(this.booking.id),
          this.getPerformancePositionsByBookingId(this.booking.id),
          this.getBookingPaymentStatuses(this.booking.id),
        ]
        await Promise.all(tasks)

        notification.success({
          message: 'Success!',
          description: 'Full Payment confirmed!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.completeFullPaymentsLoading = false
    }
  }

  @action.bound
  async sendDepositPaymentRemind(bookingId) {
    this.sendDepositPaymentRemindLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.depositPaymentsRemind(bookingId)

      if (res) {
        notification.success({
          message: 'Success!',
          description: 'Reminder Sent!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendDepositPaymentRemindLoading = false
    }
  }

  @action.bound
  async sendFullPaymentRemind(bookingId) {
    this.sendFullPaymentRemindLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.fullPaymentsRemind(bookingId)

      if (res) {
        notification.success({
          message: 'Success!',
          description: 'Reminder Sent!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendFullPaymentRemindLoading = false
    }
  }

  @action.bound
  clearFullPayments() {
    this.fullPayments = {}
  }

  @action.bound
  async getMusiciansByBookingId(bookingId) {
    this.getMusiciansLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.getMusiciansByBookingId(bookingId)

      if (res && res.payload) {
        this.musicians = res.payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getMusiciansLoading = false
    }
  }

  @action.bound
  async confirmBookedMusicians(bookingId, force = false) {
    this.confirmBookedMusiciansLoading = true

    try {
      await this.rootAPI.bookingAdminAPI.confirmBookedMusicians(
        bookingId,
        force
      )
      await this.getBookingById(this.booking.id)
    } catch (err) {
      if (err.type !== RESPONSE_TYPES.INVALID_STATE) {
        this.rootStore.errorsStore.addError(err)
      } else {
        Modal.confirm({
          title:
            'Warning: One or more gigs have unfilled positions. Do you want to proceed anyway?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: async () => {
            await this.confirmBookedMusicians(bookingId, true)
          },
        })
      }
    } finally {
      this.confirmBookedMusiciansLoading = false
    }
  }

  @action.bound
  async sendOffersToMusicians(bookingId) {
    if (this.sendOffersToMusicianLoading) {
      return
    }

    this.sendOffersToMusicianLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.sendOffersToMusicians(bookingId)

      if (res && res.type > 0) {
        this.getPerformancePositionsByBookingId(bookingId)
        notification.success({
          message: 'Success!',
          description: 'Send offers to musicians successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendOffersToMusicianLoading = false
    }
  }

  @observable openedOfferHistoryIds = []

  @action.bound
  setOpenedOfferHistoryIds(ids) {
    this.openedOfferHistoryIds = ids
  }

  @action.bound
  resetOpenedOfferHistoryIds() {
    this.openedOfferHistoryIds = []
  }

  @action.bound
  async getPerformancePositionsByBookingId(bookingId = this.booking.id) {
    this.getPerformancePositionsLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.getPerformancePositionsByBookingId(
          bookingId
        )

      if (res && res.payload) {
        this.performancePositions = res.payload

        if (this.openedOfferHistoryIds?.length) {
          await Promise.all(
            this.openedOfferHistoryIds.map(id =>
              this.getOfferHistoryByPositionId(id)
            )
          )
        }

        this.isConfirmedAllocateSingers = false
        this.allocateSingers = res.payload?.flatMap(
          performancePosition =>
            performancePosition?.positions
              ?.filter(({ isSinger }) => isSinger)
              .map(({ id: positionId, preferedSingerId }) => {
                const existingSinger = this.allocateSingers.find(
                  ({ positionId: existingPositionId }) =>
                    existingPositionId === positionId
                )

                return {
                  positionId,
                  performerId: existingSinger?.performerId || preferedSingerId,
                  performanceTypeId: performancePosition?.typeId,
                }
              }) || []
        )

        if (
          this.allocateSingers?.length &&
          !this.allocateSingers.some(item => !item.performerId)
        ) {
          this.isConfirmedAllocateSingers = true
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformancePositionsLoading = false
    }
  }

  @action.bound
  async getOfferHistoryByPositionId(id) {
    this.getOfferHistoryByPositionIdLoading = true

    try {
      const { payload } =
        await this.rootAPI.bookingAdminAPI.getOfferHistoryByPositionId(id)

      if (payload) {
        this.performancePositions = this.performancePositions.map(
          performanceType => ({
            ...performanceType,
            positions: performanceType.positions.map(position => {
              if (position.id !== id) return position

              return {
                ...position,
                offerHistory: payload,
              }
            }),
          })
        )
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getOfferHistoryByPositionIdLoading = false
    }
  }

  @action.bound
  async getCityMusiciansBySkill(cityId, skill, musicianName) {
    this.getCityMusiciansBySkillLoading = true
    const currCityId = cityId || this.booking.cityId

    try {
      if (String(musicianName).length > 0) {
        const isInCache =
          this.musiciansByNameCache[currCityId] &&
          this.musiciansByNameCache[currCityId][skill] &&
          !!this.musiciansByNameCache[currCityId][skill][musicianName]

        if (!isInCache) {
          const data = {
            name: musicianName,
            skip: 0,
            take: 10000,
          }
          const res =
            await this.rootAPI.bookingAdminAPI.getCityMusiciansBySkill(
              currCityId,
              skill,
              data
            )

          if (res && res.payload) {
            this.musiciansByName = res.payload.items
            const obj = {
              [currCityId]: {
                [skill]: {
                  [musicianName]: res.payload.items,
                },
              },
            }
            this.musiciansByNameCache = mergeDeep(
              this.musiciansByNameCache,
              obj
            )
          }
        } else {
          runInAction(() => {
            this.musiciansByName =
              this.musiciansByNameCache[currCityId][skill][musicianName]
          })
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getCityMusiciansBySkillLoading = false
    }
  }

  @action.bound
  clearMusiciansByNameCache() {
    this.musiciansByNameCache = {}
  }

  @action.bound
  async assignMusicianToPosition(data, timeData) {
    this.assignMusicianToPositionLoading = true

    try {
      const currGig = this.gigs
        .filter(
          el =>
            el &&
            el.performance &&
            el.performance.type &&
            el.performance.type.name &&
            el.performance.type.name === data.gigName
        )
        .map(el => el.id)
      const requestData = {
        ...data,
        gigId: currGig[0],
      }
      delete requestData.gigName
      await this.rootAPI.bookingAdminAPI.assignMusicianToPosition(
        this.booking.id,
        requestData
      )
      await this.savePerformancesPositionTime(requestData.positionId, timeData)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.assignMusicianToPositionLoading = false
    }
  }

  @action.bound
  async replaceMusicianPosition(data) {
    this.assignMusicianToPositionLoading = true

    try {
      const currGig = this.gigs
        .filter(
          el =>
            el?.performance?.type?.name &&
            el.performance.type.name === data.gigName
        )
        .map(el => el.id)
      const requestData = {
        ...data,
        gigId: currGig[0],
      }
      delete requestData.gigName
      await this.rootAPI.bookingAdminAPI.replaceMusicianPosition(
        this.booking.id,
        requestData
      )
      await this.getOfferHistoryByPositionId(data.positionId)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.assignMusicianToPositionLoading = false
    }
  }

  @action.bound
  async sendDirectOfferToMusician(
    positionId,
    musicianId,
    gigName,
    callback,
    force = false
  ) {
    this.assignMusicianToPositionLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.sendDirectOfferToMusician(
        positionId,
        musicianId,
        force
      )

      const hasExclusiveOffer = res.code === 9000
      const isOfferConversion = res.code === 8000

      if (hasExclusiveOffer) {
        const exclusiveOfferNotification =
          'Musician has exclusive offer. Do you want to send direct offer?'

        Modal.confirm({
          title: exclusiveOfferNotification,
          okText: 'Yes',
          cancelText: 'No',
          width: 800,
          onOk: () => {
            this.sendDirectOfferToMusician(
              positionId,
              musicianId,
              gigName,
              callback,
              true
            )
          },
        })
      } else if (isOfferConversion) {
        const offerConversionNotification =
          'As the position is already booked, please confirm if you want to cancel the booked musician and to send the offer to another musician.'

        const musicianData = {
          gigName,
          positionId,
          musicianId,
        }
        Modal.confirm({
          title: 'Confirmation!',
          content: offerConversionNotification,
          okText: 'Yes',
          cancelText: 'No',
          width: 800,
          onOk: async () => {
            await this.replaceMusicianPosition(musicianData)
            callback()
          },
        })
      } else {
        notification.success({
          message: 'Success!',
          description: 'Direct offer sent successfully!',
          placement: 'bottomRight',
        })
        await this.getOfferHistoryByPositionId(positionId)
        callback()
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.assignMusicianToPositionLoading = false
    }
  }

  @action.bound
  async savePerformancesPositionTime(positionId, data) {
    this.savePerformancesPositionTimeLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.savePerformancesPositionTime(
          positionId,
          data
        )

      if (res) {
        await this.getPerformancePositionsByBookingId()
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.savePerformancesPositionTimeLoading = false
    }
  }

  @action.bound
  async getEventTimeline(
    id = this.booking.event && this.booking.event.eventTimelineId
  ) {
    try {
      if (!id) {
        runInAction(() => {
          this.eventTimelineId = null
          this.eventTimeline = []
        })
      } else {
        const res = await this.rootAPI.bookingAdminAPI.getEventTimeline(id)
        runInAction(() => {
          this.eventTimelineId = res.payload.id
          this.eventTimeline = res.payload.moments
          this.eventTimelineTemplateMoments = res.payload.moments
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @persist('list') @observable eventTimelineTemplateMoments = []

  @observable eventTimelineTemplateMomentsLoading = false

  @action.bound
  async getEventTimelineTemplateDetail(templateId) {
    try {
      if (templateId) {
        this.eventTimelineTemplateMomentsLoading = true
        const res =
          await this.rootAPI.bookingAdminAPI.getEventTimeline(templateId)
        this.eventTimelineTemplateMoments = res.payload.moments
      } else {
        this.eventTimelineTemplateMoments = []
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.eventTimelineTemplateMomentsLoading = false
    }
  }

  @action.bound
  async saveEventTimelineAsTemplate(templateName, data, fileList) {
    this.eventTimelineTemplateMomentsLoading = true
    let templateId = ''

    try {
      const specData = {
        name: templateName,
        moments: data,
      }
      const { payload } =
        await this.rootAPI.bookingAdminAPI.createEventTimeline(specData)

      if (payload) {
        if (payload?.moments?.length > 0) {
          const musicId = payload.moments[0].value

          if (musicId?.length > 0) {
            if (Array.isArray(fileList) && fileList.length > 0) {
              const promises = fileList.map(file =>
                this.getUploadSongUrl(musicId, {
                  objectName: file.name,
                  file,
                })
              )
              await Promise.all(promises)
            }
          }
        }

        await this.getBookingById()
        await this.getEventTimeline()
        await this.getEventTimelineTemplate()
        await this.getEventTimelineTemplateDetail(payload.id)
        templateId = payload.id
        notification.success({
          message: 'Success!',
          description: `Template ${templateName} created!`,
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.eventTimelineTemplateMomentsLoading = false
    }

    return templateId
  }

  @action.bound
  async createEventTimeline(data, fileList) {
    this.createEventTimelineLoading = true
    let success = false

    try {
      const { payload } =
        await this.rootAPI.bookingAdminAPI.createEventTimeline(data)

      if (payload?.moments?.length > 0) {
        const musicId = payload.moments[0].value

        if (musicId?.length > 0) {
          if (Array.isArray(fileList) && fileList.length > 0) {
            const promises = fileList.map(file =>
              this.getUploadSongUrl(musicId, {
                objectName: file.name,
                file,
              })
            )
            await Promise.all(promises)
          }
        }

        await this.getBookingById()
        await this.getEventTimeline()
        notification.success({
          message: 'Success!',
          description: 'Timeline updated!',
          placement: 'bottomRight',
        })
        success = true
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createEventTimelineLoading = false
    }

    return success
  }

  @action.bound
  async updateEventTimeline(data) {
    this.createEventTimelineLoading = true
    let success = false

    try {
      if (!this.eventTimelineId) {
        const specData = {
          eventId: this.booking.eventId,
          moments: [data],
        }
        success = await this.createEventTimeline(specData, data.fileList)
      } else {
        const specData = {
          eventTimelineId: this.eventTimelineId,
          ...data,
        }
        const { payload } =
          await this.rootAPI.bookingAdminAPI.updateEventTimeline(specData)

        if (payload) {
          const musicId = payload.value

          if (musicId?.length > 0) {
            if (Array.isArray(data.fileList) && data.fileList.length > 0) {
              const promises = data.fileList.map(file =>
                this.getUploadSongUrl(musicId, {
                  objectName: file.name,
                  file,
                })
              )
              await Promise.all(promises)
            }
          }

          await this.getBookingById()
          await this.getEventTimeline()
          notification.success({
            message: 'Success!',
            description: 'Timeline updated!',
            placement: 'bottomRight',
          })
          success = true
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createEventTimelineLoading = false
    }

    return success
  }

  @action.bound
  async saveEventTimeline(data, eventTimelineId, eventTimelineName) {
    this.eventTimelineTemplateMomentsLoading = true
    let success = false

    try {
      const day = moment().format(MOMENT_DATE_FORMAT)
      const requestData = data.map(x => {
        const when = `${day}T${moment(x.when).format(TIME_FORMAT)}:00`

        if (x.id && (x.id.length < 3 || typeof x.id === 'number')) {
          return { ...x, id: null, when }
        }

        return { ...x, when }
      })
      let specData = { moments: requestData }

      if (!eventTimelineId) {
        specData = {
          eventId: this.booking.eventId,
          moments: requestData,
        }
        success = await this.createEventTimeline(specData, data.fileList)
      } else {
        if (eventTimelineName) {
          specData = {
            moments: requestData,
            name: eventTimelineName,
          }
        }

        const { payload } =
          await this.rootAPI.bookingAdminAPI.updateEventTimeline(
            eventTimelineId,
            specData
          )

        if (payload) {
          const musicId = payload.value

          if (musicId?.length > 0) {
            if (Array.isArray(data.fileList) && data.fileList.length > 0) {
              const promises = data.fileList.map(file =>
                this.getUploadSongUrl(musicId, {
                  objectName: file.name,
                  file,
                })
              )
              await Promise.all(promises)
            }
          }

          await this.getBookingById()
          await this.getEventTimeline()
          await this.getEventTimelineTemplateDetail(eventTimelineId)
          notification.success({
            message: 'Success!',
            description: 'Timeline saved!',
            placement: 'bottomRight',
          })
          success = true
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.eventTimelineTemplateMomentsLoading = false
    }

    return success
  }

  @action.bound
  async insertMomentEventTimeline(data, eventTimelineId) {
    this.eventTimelineTemplateMomentsLoading = true

    try {
      let timelineId = eventTimelineId

      if (!eventTimelineId) {
        timelineId = await this.initEmptyEventTimeline()
      }

      const { payload } = await this.rootAPI.bookingAdminAPI.insertEventMoment(
        timelineId,
        data
      )

      if (payload) {
        await this.getBookingById()
        await this.getEventTimeline()
        await this.getEventTimelineTemplateDetail(eventTimelineId)
        notification.success({
          message: 'Success!',
          description: 'Insert Success!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.eventTimelineTemplateMomentsLoading = false
    }
  }

  @action.bound
  async downloadEventTimelineTemplate(eventTimeLineId) {
    let template

    try {
      const fileName = `EventTimeLine_${getTicks(new Date())}.csv`

      template =
        await this.rootAPI.bookingAdminAPI.downloadEventTimelineTemplate(
          eventTimeLineId,
          fileName
        )
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createEventTimelineLoading = false
    }

    return template
  }

  @action.bound
  async exportEventTimelineTemplate() {
    let template

    try {
      const fileName = `EventTimeLine_${getTicks(new Date())}.csv`

      template =
        await this.rootAPI.bookingAdminAPI.exportEventTimelineTemplate(fileName)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createEventTimelineLoading = false
    }

    return template
  }

  @action.bound
  async initEmptyEventTimeline() {
    this.createEventTimelineLoading = true

    try {
      const data = {
        eventId: this.booking.eventId,
        moments: [],
      }
      const { payload } =
        await this.rootAPI.bookingAdminAPI.createEventTimeline(data)

      if (payload) {
        runInAction(() => {
          this.eventTimelineId = payload.id
        })

        return payload.id
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createEventTimelineLoading = false
    }

    return null
  }

  @action.bound
  async uploadEventTimeline(eventTimeLineId, data) {
    this.createEventTimelineLoading = true
    let success = false
    let timelineId = eventTimeLineId

    try {
      if (data.fileList?.length > 0) {
        if (!eventTimeLineId) {
          timelineId = await this.initEmptyEventTimeline()
        }

        const promises = data.fileList.map(file =>
          this.getUploadEventTimelineUrl(timelineId, {
            objectName: file.name,
            contentType: file.type,
            file,
          })
        )

        await Promise.all(promises)
      }

      setTimeout(async () => {
        await this.getBookingById()
        await this.getEventTimeline()
      }, 5000)

      notification.success({
        message: 'Success!',
        description: 'Timeline updated!',
        placement: 'bottomRight',
      })
      success = true
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createEventTimelineLoading = false
    }

    return success
  }

  @action.bound
  async getUploadEventTimelineUrl(id, data) {
    this.getUploadEventTimelineUrlLoading = true

    try {
      const { payload } =
        await this.rootAPI.bookingAdminAPI.getUploadEventTimelineUrl(id, data)

      if (payload) {
        const { file } = data
        const contentType = setCorrectContentTypes(file.name)

        await this.rootAPI.bookingAdminAPI.uploadEventTimelineCsv(
          payload.signedUrl,
          file,
          contentType
        )
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getUploadEventTimelineUrlLoading = false
    }
  }

  @action.bound
  async updateEventMoment(data) {
    this.createEventTimelineLoading = true
    this.eventTimelineTemplateMomentsLoading = true
    let success = false

    try {
      const specData = { ...data }
      delete specData.eventMomentId
      delete specData.fileList
      const { payload } = await this.rootAPI.bookingAdminAPI.updateEventMoment(
        data.eventMomentId,
        specData
      )

      if (payload) {
        const musicId = payload.value

        if (musicId?.length > 0) {
          if (Array.isArray(data.fileList) && data.fileList.length > 0) {
            const promises = data.fileList.map(file =>
              this.getUploadSongUrl(musicId, {
                objectName: file.name,
                file,
              })
            )
            await Promise.all(promises)
          }
        }

        await this.getBookingById()
        await this.getEventTimeline()
        notification.success({
          message: 'Success!',
          description: 'Time moment updated!',
          placement: 'bottomRight',
        })
        success = true
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createEventTimelineLoading = false
      this.eventTimelineTemplateMomentsLoading = false
    }

    return success
  }

  @action.bound
  async deleteEventMoment(eventMomentId) {
    this.createEventTimelineLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.deleteEventMoment(eventMomentId)

      if (res) {
        await this.getBookingById()
        await this.getEventTimeline()
        notification.success({
          message: 'Success!',
          description: 'Time moment deleted!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createEventTimelineLoading = false
      this.eventTimelineTemplateMomentsLoading = false
    }
  }

  @action.bound
  async deleteEventTimeline(eventTimelineId) {
    this.eventTimelineTemplateMomentsLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.deleteEventTimeline(eventTimelineId)

      if (res) {
        await this.getBookingById()
        await this.getEventTimeline()
        notification.success({
          message: 'Success!',
          description: 'Timeline deleted!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.eventTimelineTemplateMomentsLoading = false
    }
  }

  @action.bound
  async sendKeySong(gigId, data) {
    this.sendKeySongLoading = true

    try {
      const { fileList } = data
      const newData = { ...data }
      delete newData.fileList
      const res = await this.rootAPI.bookingAdminAPI.sendKeySong(gigId, newData)

      if (res && res.payload) {
        const { id: musicId } = res.payload

        if (Array.isArray(fileList) && fileList.length > 0) {
          const promises = fileList.map(file =>
            this.getUploadSongUrl(musicId, {
              objectName: file.name,
              file,
            })
          )
          await Promise.all(promises)
        }

        await this.getGig(gigId)
        notification.success({
          message: 'Success!',
          description: 'The song has been sent successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendKeySongLoading = false
    }
  }

  @action.bound
  async updateKeySong(gigId, keySongId, data) {
    this.sendKeySongLoading = true

    try {
      const { fileList } = data
      const newData = {
        ...data,
        fileLinkIds: fileList.map(el => el.id).filter(el => el),
      }
      delete newData.fileList
      const res = await this.rootAPI.bookingAdminAPI.updateKeySong(
        keySongId,
        newData
      )

      if (res) {
        if (Array.isArray(fileList) && fileList.length > 0) {
          const promises = fileList
            .filter(f => !f.id)
            .map(file =>
              this.getUploadSongUrl(keySongId, {
                objectName: file.name,
                file,
              })
            )
          await Promise.all(promises)
        }

        await this.getGig(gigId)
        await this.getKeySong(keySongId)
        notification.success({
          message: 'Success!',
          description: 'The song has been updated successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendKeySongLoading = false
    }
  }

  @action.bound
  async deleteKeySong(gigId, keySongId) {
    this.deleteKeySongLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.deleteKeySong(keySongId)

      if (res) {
        await this.getGig(gigId)
        notification.success({
          message: 'Success!',
          description: 'The song has been deleted successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.deleteKeySongLoading = false
    }
  }

  @action.bound
  async sendPlaylist(gigId, data) {
    this.sendPlaylistLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.sendPlaylist(gigId, data)

      if (res && res.payload) {
        notification.success({
          message: 'Success!',
          description: 'The playlist has been sent successfully!',
          placement: 'bottomRight',
        })
        await this.getGig(gigId)
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendPlaylistLoading = false
    }
  }

  @action.bound
  async updatePlaylist(gigId, playlistId, data) {
    this.sendPlaylistLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.updatePlaylist(
        playlistId,
        data
      )

      if (res) {
        await this.getGig(gigId)
        notification.success({
          message: 'Success!',
          description: 'The playlist has been updated successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendPlaylistLoading = false
    }
  }

  @action.bound
  async deletePlaylist(gigId, playlistId) {
    this.deletePlaylistLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.deletePlaylist(playlistId)

      if (res) {
        await this.getGig(gigId)
        notification.success({
          message: 'Success!',
          description: 'The playlist has been deleted successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.deletePlaylistLoading = false
    }
  }

  @action.bound
  async sendTopSong(gigId, data) {
    this.sendTopSongLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.sendTopSong(gigId, data)

      if (res && res.payload) {
        notification.success({
          message: 'Success!',
          description: 'The top song has been sent successfully!',
          placement: 'bottomRight',
        })
        await this.getGig(gigId)
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendTopSongLoading = false
    }
  }

  @action.bound
  async updateTopSong(gigId, topSongId, data) {
    this.sendTopSongLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.updateTopSong(
        topSongId,
        data
      )

      if (res) {
        await this.getGig(gigId)
        notification.success({
          message: 'Success!',
          description: 'The top song has been updated successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendTopSongLoading = false
    }
  }

  @action.bound
  async deleteTopSong(gigId, topSongId) {
    this.deleteTopSongLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.deleteTopSong(topSongId)

      if (res) {
        await this.getGig(gigId)
        notification.success({
          message: 'Success!',
          description: 'The top song has been deleted successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.deleteTopSongLoading = false
    }
  }

  @action.bound
  async reorderTopSongs(gigId, data) {
    this.sendTopSongLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.reorderTopSongs(
        gigId,
        data
      )

      if (res) {
        await this.getGig(gigId)
        notification.success({
          message: 'Success!',
          description: 'Top Songs have been reordered successfully!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.sendTopSongLoading = false
    }
  }

  @action.bound
  async getUploadSongUrl(id, data) {
    this.getUploadSongUrlLoading = true

    try {
      const { payload } = await this.rootAPI.bookingAdminAPI.getUploadSongUrl(
        id,
        data
      )

      if (payload) {
        const { file } = data
        const contentType = setCorrectContentTypes(file.name)
        await this.rootAPI.bookingAdminAPI.uploadSongProductUrl(
          payload.signedUrl,
          file,
          contentType
        )
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getUploadSongUrlLoading = false
    }
  }

  @action.bound
  async getGig(gigId) {
    this.getUploadSongUrlLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.getGig(gigId)

      if (res && res.payload) {
        runInAction(() => {
          this.gigs = this.gigs.map(el => {
            const newEl = { ...el }

            if (newEl.id === res.payload.id) {
              return {
                ...newEl,
                ...res.payload,
              }
            }

            return newEl
          })
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getUploadSongUrlLoading = false
    }
  }

  @action.bound
  async getGigKeySongs(gigId) {
    try {
      const res = await this.rootAPI.bookingAdminAPI.getGigKeySongs(gigId)

      if (res && res.payload) {
        return res.payload
      }
    } catch (err) {
      if (err.type === -1) {
        return []
      }

      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getKeySong(keySongId) {
    this.getUploadSongUrlLoading = true

    try {
      const res = await this.rootAPI.bookingAdminAPI.getKeySong(keySongId)

      if (res && res.payload) {
        this.keySongsFiles = {
          ...this.keySongsFiles,
          [keySongId]: res.payload,
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getUploadSongUrlLoading = false
    }
  }

  @action.bound
  async getBookingPaymentStatuses(id) {
    this.bookingPaymentStatusesLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.getPaymentStatusByBookingId(id)

      if (res && res.payload) {
        this.bookingPaymentStatuses = res.payload.sort(
          (a, b) => moment(a.createdDate) - moment(b.createdDate)
        )
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.bookingPaymentStatusesLoading = false
    }
  }

  @action.bound
  async downloadBookingSheet(booking = this.booking) {
    let template

    try {
      this.downloadBookingSheetLoading = true
      const fileName = `BookingSheet_${booking.bookingNumber}_${moment().format(
        MOMENT_DATE_FORMAT
      )}.pdf`

      template = await this.rootAPI.bookingAdminAPI.downloadBookingSheet(
        booking.id,
        fileName
      )
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.downloadBookingSheetLoading = false
    }

    return template
  }

  @action.bound
  async saveMusicianPaymentsNote(paymentId, noteContent) {
    try {
      const res = await this.rootAPI.bookingAdminAPI.saveMusicianPaymentsNote(
        paymentId,
        { note: noteContent }
      )

      if (res) {
        notification.success({
          message: 'Success!',
          description: 'Musician payment note update success!',
          placement: 'bottomRight',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @observable editEventTimeLineModalOpen = false

  @action.bound
  toggleEditEventTimeLineModal = () => {
    this.editEventTimeLineModalOpen = !this.editEventTimeLineModalOpen
  }

  @persist('list') @observable eventTimelineTemplates = []

  @action.bound
  async getEventTimelineTemplate() {
    try {
      const { payload } =
        await this.rootAPI.bookingAdminAPI.getEventTimelineTemplate()

      if (payload) {
        this.eventTimelineTemplates = payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  /*
  getUploadSongUrl
  */

  @observable downloadPayListCSVLoading = false

  @action.bound
  downloadPayListCSV = async () => {
    this.downloadPayListCSVLoading = true
    const timeStamp = new Date().toJSON()
    const fileName = `Musicians_Payment_List_${timeStamp}.csv`

    try {
      const { payload } = await this.rootAPI.bookingAdminAPI.downloadPayListCSV(
        this.booking.id
      )
      const decodedData = atob(payload)
      const blob = new Blob([decodedData], { type: 'text/csv;charset=utf-8' })
      FileSaver.saveAs(blob, fileName)
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.downloadPayListCSVLoading = false
    }
  }

  @action.bound
  toggleShowCancelRecords(value) {
    this.showCancelRecords = value
  }

  @observable changeMusicianPaymentStatusLoading = false

  @action.bound
  changeMusicianPaymentStatus = async (invoiceId, status, callback) => {
    this.changeMusicianPaymentStatusLoading = true

    try {
      const res =
        await this.rootAPI.bookingAdminAPI.changeMusicianPaymentStatus(
          invoiceId,
          status
        )

      if (res) {
        await this.getMusicianPayments()
      }

      return res
    } catch (err) {
      callback()
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.changeMusicianPaymentStatusLoading = false
    }
  }

  @observable musicianPayNowLoading = false

  @action.bound
  musicianPayNow = async (bookingId, callback = () => {}) => {
    this.musicianPayNowLoading = true
    let isSuccess = false

    try {
      const res = await this.rootAPI.bookingAdminAPI.musicianPayNow(bookingId)

      if (res) {
        isSuccess = true
      }
    } catch (err) {
      callback()
      this.rootStore.errorsStore.addError(err)
    }

    this.musicianPayNowLoading = false

    return isSuccess
  }

  @action.bound
  updateBookingRecentlyViewStatus = async (bookingId, status) => {
    const { selectedCityId } = this.rootStore.cityStore

    const viewedBookings =
      (this.recentlyViewedBookings[selectedCityId] && [
        ...this.recentlyViewedBookings[selectedCityId],
      ]) ||
      []

    this.updateRecentlyViewedBookings(
      viewedBookings.map(item => {
        if (item.id === bookingId) {
          return { ...item, status }
        }

        return item
      })
    )

    // In some case, when we update booking status, the upcoming and previous don't auto update
    // So we have to update it
    this.upcomingBookings = this.upcomingBookings.map(item => ({
      ...item,
      status: bookingId === item.id ? status : item.status,
    }))

    this.previousBookings = this.previousBookings.map(item => ({
      ...item,
      status: bookingId === item.id ? status : item.status,
    }))
  }

  @observable downloadMusicianInvoiceLoading = false

  @action.bound
  async downloadMusicianInvoice(invoiceId) {
    try {
      this.downloadMusicianInvoiceLoading = true
      const fileName = `Invoice_${invoiceId}_${moment().format(
        MOMENT_DATE_FORMAT
      )}.pdf`

      const { payload } =
        await this.rootAPI.bookingAdminAPI.downloadMusicianInvoice(invoiceId)

      const byteCharacters = atob(payload)
      const byteArray = new Uint8Array(
        byteCharacters.split('').map(char => char.charCodeAt(0))
      )
      const blob = new Blob([byteArray], { type: 'application/pdf' })

      FileSaver.saveAs(blob, fileName)
    } catch (e) {
      this.rootStore.errorsStore.addError(e)
    } finally {
      this.downloadMusicianInvoiceLoading = false
    }
  }

  @observable completeKeysongsLoading = false

  @action.bound
  completeKeySongs = async status => {
    this.completeKeysongsLoading = true

    try {
      set(this.booking, 'event.isCompleteKeysongs', status)

      await this.rootAPI.bookingAdminAPI.completeKeySongs(
        this.booking.eventId,
        status
      )
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    this.completeKeysongsLoading = false
  }

  @observable completeEventTimelineLoading = false

  @action.bound
  completeEventTimeline = async status => {
    this.completeEventTimelineLoading = true

    try {
      set(this.booking, 'event.isCompleteEventTimeline', status)

      await this.rootAPI.bookingAdminAPI.completeEventTimeline(
        this.booking.eventId,
        status
      )
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    this.completeEventTimelineLoading = false
  }

  @action.bound
  setAllocateSingers = singers => {
    this.allocateSingers = singers
  }

  @action.bound
  addAllocateSingers = singer => {
    this.allocateSingers = [
      ...this.allocateSingers?.filter(
        item => item?.positionId !== singer?.positionId
      ),
      singer,
    ]
  }

  @observable confirmSingerGigAllocationLoading = false

  @action.bound
  confirmSingerGigAllocation = async () => {
    this.confirmSingerGigAllocationLoading = true

    try {
      await this.rootAPI.bookingAdminAPI.updateAllocateSingers({
        eventId: this.booking.eventId,
        singers: this.allocateSingers,
      })

      notification.success({
        message: 'Success!',
        placement: 'bottomRight',
      })

      this.isConfirmedAllocateSingers = true
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    this.confirmSingerGigAllocationLoading = false
  }

  @observable currentPerformancePosition = null

  @action.bound
  setCurrentPerformancePosition(position) {
    this.currentPerformancePosition = position
  }
}

export default BookingAdminStore
