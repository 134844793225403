import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import React, { Component } from 'react'

import loadable from '@loadable/component'
import PropTypes from 'prop-types'

const Header = loadable(() => import('../components/Header'))

@inject(stores => ({
  selectedCity: stores.bookingStore.selectedCity,
  websiteUrl: stores.bookingStore.websiteUrl,
}))
@observer
class HeaderContainer extends Component {
  static propTypes = {
    selectedCity: MobxPropTypes.objectOrObservableObject,
    showCityDropdown: PropTypes.bool,
    title: PropTypes.string,
    websiteUrl: PropTypes.string.isRequired,
  }

  static defaultProps = {
    selectedCity: {},
    showCityDropdown: true,
    title: 'Wedding Music Estimator',
  }

  render() {
    const { selectedCity, showCityDropdown, title, websiteUrl } = this.props

    return (
      <Header
        selectedCity={selectedCity}
        showCityDropdown={showCityDropdown}
        title={title}
        websiteUrl={websiteUrl}
      />
    )
  }
}

export default HeaderContainer
