import { action, computed, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'

import { InvoiceStatus } from '@/constants'
import { DEFAULT_PAGINATION } from '@/flynk.app.web.core.data/constants/pagination'
import resettableMixin from '@/flynk.app.web.core.data/stores/resettableMixin'
import { orderByCreatedDate } from '@/helpers/common'
import { checkIsMissedOffer, getFormattedOffers } from '@/helpers/offers'

@resettableMixin
class OfferStore {
  @persist('list') @observable incomingOffers = []

  @observable incomingOffersPagination = {
    ...DEFAULT_PAGINATION,
    pageSize: 100,
  }

  @observable getIncomingOffersLoading = false

  @persist('list') @observable futureBookings = []

  @persist('list') @observable previousBookings = []

  @persist('object') @observable nextGigBooking = {}

  @observable getNextGigPerformersByBookingIdLoading = false

  @persist('object') @observable nextGigBookingPerformers = []

  @observable futureBookingsPagination = {
    ...DEFAULT_PAGINATION,
    pageSize: 100,
  }

  @observable previousBookingsPagination = {
    ...DEFAULT_PAGINATION,
    pageSize: 100,
  }

  @observable getPreviousBookingsLoading = false

  @observable getFutureBookingsLoading = false

  @observable acceptOfferGroupLoading = false

  @observable confirmAvailabilityLoading = false

  @computed get formattedIncomingOffers() {
    return getFormattedOffers(this.incomingOffers)
  }

  @computed get formattedFutureBookings() {
    return getFormattedOffers(this.futureBookings)
  }

  @computed get formattedPreviousBookings() {
    return getFormattedOffers(this.previousBookings)
  }

  @computed get nextGig() {
    return (this.formattedFutureBookings || [])
      .filter(futureBooking => !!futureBooking[0].date.value)
      .sort(
        (a, b) => Date.parse(a[0].date.value) - Date.parse(b[0].date.value)
      )[0]
  }

  @computed get currentOffer() {
    const {
      bookingAdminStore: { booking },
    } = this.rootStore
    if (!booking || !booking.id) return []

    const currOffer = orderByCreatedDate(
      [
        ...this.formattedIncomingOffers,
        ...this.formattedFutureBookings,
        ...this.formattedPreviousBookings,
      ]
        .filter(offer => {
          const bookingIdMatch = offer[0].bookingId === booking.id
          const currentGroupOfferIdMatch =
            this.currentGroupOfferId === offer[0].groupOfferId

          return this.currentGroupOfferId
            ? bookingIdMatch && currentGroupOfferIdMatch
            : bookingIdMatch
        })
        .flat()
    )

    return currOffer || []
  }

  @computed get nextGigBookingId() {
    if (this.nextGig?.length) {
      const { bookingId } = this.nextGig[0]

      return bookingId
    }

    return ''
  }

  @computed get upcomingPay() {
    const allOffers = [
      ...this.formattedIncomingOffers,
      ...this.formattedFutureBookings,
    ]
    const bookingIds = []

    if (!allOffers.length) return 0

    return allOffers.reduce((sum, offer) => {
      const { total, bookingId = '' } = offer[0]

      // If we have the same bookingId, We will use booking in incomingOffer
      // Because booking in incomingOffer includes the other
      if (bookingIds.includes(bookingId)) return sum

      bookingIds.push(bookingId)
      const isMissedOffer = checkIsMissedOffer(offer)

      return isMissedOffer ? sum : sum + total
    }, 0)
  }

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @observable currentGroupOfferId = null

  @computed get isCurrentOfferPaid() {
    return this.currentOffer?.every(item =>
      item?.payments?.every(payment => payment?.status === InvoiceStatus.Paid)
    )
  }

  @action.bound
  setCurrentGroupOfferId(id) {
    this.currentGroupOfferId = id
  }

  @action.bound
  async acceptOfferGroup(groupOfferId) {
    this.acceptOfferGroupLoading = true

    try {
      const res = await this.rootAPI.offerAPI.acceptOfferGroup(groupOfferId)

      if (res) {
        await Promise.all([this.getIncomingOffers(), this.getFutureBookings()])
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.acceptOfferGroupLoading = false
    }
  }

  @action.bound
  async declineOfferGroup(groupOfferId) {
    this.declineOfferGroupLoading = true

    try {
      const res = await this.rootAPI.offerAPI.declineOfferGroup(groupOfferId)

      if (res) {
        await this.getIncomingOffers()
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.declineOfferGroupLoading = false
    }
  }

  @action.bound
  async confirmAvailability(groupOfferId) {
    this.confirmAvailabilityLoading = true

    try {
      const res = await this.rootAPI.offerAPI.confirmAvailability(groupOfferId)

      if (res) {
        await Promise.all([this.getIncomingOffers()])
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.confirmAvailabilityLoading = false
    }
  }

  @action.bound
  async getIncomingOffers(
    musicianUserId = this.rootStore.profileStore.userId,
    data = {
      skip: 0,
      take: this.incomingOffersPagination.pageSize,
    }
  ) {
    this.getIncomingOffersLoading = true

    try {
      const { currentOrganisationId } = this.rootStore.profileStore
      const viewDate = new Date()

      const res = await this.rootAPI.offerAPI.getIncomingOffers(
        musicianUserId,
        {
          ...data,
          cityId: currentOrganisationId,
          viewDate,
        }
      )

      if (res && res.payload) {
        this.incomingOffers = res.payload.items
        this.setIncomingOffersPagination({
          ...this.incomingOffersPagination,
          current: res.payload.page,
          pageSize: res.payload.pageSize,
          total: res.payload.totalItems,
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getIncomingOffersLoading = false
    }
  }

  @action.bound
  async getFutureBookings(
    musicianUserId = this.rootStore.profileStore.userId,
    data = {
      skip: 0,
      take: this.futureBookingsPagination.pageSize,
    }
  ) {
    const { currentOrganisationId, isMusician } = this.rootStore.profileStore
    const { musician } = this.rootStore.userStore
    const viewDate = new Date()

    this.getFutureBookingsLoading = true

    try {
      const res = await this.rootAPI.offerAPI.getFutureBookings(
        musicianUserId,
        {
          ...data,
          cityId: isMusician
            ? currentOrganisationId
            : musician.performers[0].cityId,
          viewDate,
        }
      )

      if (res && res.payload) {
        this.futureBookings = res.payload.items
        this.setFutureBookingsPagination({
          ...this.futureBookingsPagination,
          current: res.payload.page,
          pageSize: res.payload.pageSize,
          total: res.payload.totalItems,
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getFutureBookingsLoading = false
    }
  }

  @action.bound
  async getPreviousBookings(
    musicianUserId = this.rootStore.profileStore.userId,
    data = {
      skip: 0,
      take: this.previousBookingsPagination.pageSize,
    }
  ) {
    const { currentOrganisationId } = this.rootStore.profileStore
    const viewDate = new Date()

    this.getPreviousBookingsLoading = true

    try {
      const res = await this.rootAPI.offerAPI.getPreviousBookings(
        musicianUserId,
        {
          ...data,
          cityId: currentOrganisationId,
          viewDate,
        }
      )

      if (res && res.payload) {
        this.previousBookings = res.payload.items
        this.setPreviousBookingsPagination({
          ...this.previousBookingsPagination,
          current: res.payload.page,
          pageSize: res.payload.pageSize,
          total: res.payload.totalItems,
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPreviousBookingsLoading = false
    }
  }

  @action.bound
  async getNextGigBookingById(
    id = this.rootStore.bookingAdminStore.booking.id
  ) {
    this.rootStore.bookingAdminStore.getBookingByIdLoading = true
    let res

    try {
      res = await this.rootAPI.bookingAdminAPI.getBookingById(id)
      runInAction(() => {
        this.nextGigBooking = res.payload
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.rootStore.bookingAdminStore.getBookingByIdLoading = false
    }

    return res
  }

  @action.bound
  async getNextGigPerformersByBookingId(id = this.nextGigBooking.id) {
    this.getNextGigPerformersByBookingIdLoading = true

    try {
      const res = await this.rootAPI.performerAPI.getPerformersByBookingId(id)

      if (res && res.payload) {
        this.nextGigBookingPerformers = res.payload.map(performer => ({
          ...performer,
          gigOffers: orderByCreatedDate(performer?.gigOffers),
        }))
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getNextGigPerformersByBookingIdLoading = false
    }
  }

  @action.bound
  resetNextGigData() {
    this.nextGigBooking = {}
    this.nextGigBookingPerformers = []
  }

  @action.bound
  setIncomingOffersPagination = pagination => {
    this.incomingOffersPagination = pagination
  }

  @action.bound
  setFutureBookingsPagination = pagination => {
    this.futureBookingsPagination = pagination
  }

  @action.bound
  setPreviousBookingsPagination = pagination => {
    this.previousBookingsPagination = pagination
  }
}

export default OfferStore
