export default class BookingAdminAPI {
  constructor(args) {
    this.request = args.request
  }

  getBookingsByCity = data =>
    this.request.get('/Bookings/Find', {
      params: data,
    })

  getUpcomingBookings = data =>
    this.request.get('/Bookings/Upcoming', {
      params: data,
    })

  getPreviousBookings = data =>
    this.request.get('/Bookings/Previous', {
      params: data,
    })

  getBookingById = id => this.request.get(`Booking/${id}`)

  getPaymentStatusByBookingId = id =>
    this.request.get(`Booking/${id}/PaymentStatus`)

  updateBooking = (bookingId, data, force) =>
    this.request.put(`Booking/${bookingId}/?force=${force}`, data)

  updateBookingStatus = (bookingId, status) =>
    this.request.put(`Booking/${bookingId}/ChangeStatus/${status}`)

  unArchivedBooking = bookingId =>
    this.request.put(`Booking/${bookingId}/UnArchive`)

  deleteBooking = id => this.request.del(`Booking/${id}`)

  getQuotePricesByBookingId = id =>
    this.request.get(`Booking/${id}/QuotePrices`)

  getFirmPricesByBookingId = id => this.request.get(`Booking/${id}/FirmPrices`)

  updateQuotePrice = (bookingId, data) =>
    this.request.post(`Booking/${bookingId}/Invoices`, data)

  updateFirmPrice = (bookingId, data) =>
    this.request.post(`Booking/${bookingId}/Invoices`, data)

  // Musician Invoices
  getMusicianPayments = bookingId =>
    this.request.get(`Booking/${bookingId}/MusicianPayments`)

  getMusicianPaymentById = (bookingId, musicianId) =>
    this.request.get(`Booking/${bookingId}/Musician/${musicianId}/Payment`)

  createMusicianPayments = (invoiceId, data) =>
    this.request.post(`Booking/MusicianInvoices/${invoiceId}`, data)

  confirmAllPayments = bookingId =>
    this.request.post(`Booking/${bookingId}/MusicianPayments/Confirm`)

  depositPaymentsRemind = bookingId =>
    this.request.put(`Booking/${bookingId}/DepositPayments/Remind`)

  fullPaymentsRemind = bookingId =>
    this.request.put(`Booking/${bookingId}/FullPayments/Remind`)

  saveMusicianPaymentsNote = (paymentId, data) =>
    this.request.post(`Booking/MusicianPayments/${paymentId}`, data)

  /* Check Availability [ */
  checkAvailabilityByBookingId = bookingId =>
    this.request.post(`Booking/${bookingId}/Offers/AvailabilityChecking`)

  reserveSinger = (performerId, eventId) =>
    this.request.post(
      `Bookings/Singer/${performerId}/Reserve?eventId=${eventId}`
    )

  cancelSingerReservation = (performerId, eventId) =>
    this.request.post(
      `Bookings/Singer/${performerId}/CancelReservation?eventId=${eventId}`
    )

  cancelSingerJob = (performerId, eventId) =>
    this.request.post(
      `Bookings/Singer/${performerId}/CancelJob?eventId=${eventId}`
    )
  /* ] Check Availability */

  getDepositPaymentsByBookingId = bookingId =>
    this.request.get(`Booking/${bookingId}/DepositPayments`)

  sendDepositPayments = data =>
    this.request.post('/Bookings/DepositPayments', data)

  completeDepositPayments = bookingId =>
    this.request.put(`Booking/${bookingId}/DepositPayments/Complete`)

  completeDepositPaymentsByPaymentId = paymentId =>
    this.request.put(`Bookings/DepositPayments/Complete?paymentId=${paymentId}`)

  getFullPaymentsByBookingId = bookingId =>
    this.request.get(`Booking/${bookingId}/FullPayments`)

  sendFullPayments = data => this.request.post('/Bookings/FullPayments', data)

  completeFullPayments = bookingId =>
    this.request.put(`Booking/${bookingId}/FullPayments/Complete`)

  completeFullPaymentsByPaymentId = paymentId =>
    this.request.put(`Bookings/FullPayments/Complete?paymentId=${paymentId}`)

  getMusiciansByBookingId = bookingId =>
    this.request.get(`Booking/${bookingId}/Musicians`)

  getOfferHistoryByPositionId = positionId =>
    this.request.get(`Booking/Position/${positionId}/OfferHistory`)

  confirmBookedMusicians = (bookingId, force) =>
    this.request.put(
      `Booking/${bookingId}/ConfirmBookedMusician?force=${force}`
    )

  sendOffersToMusicians = bookingId =>
    this.request.post(`Booking/${bookingId}/Offers/SendUsingUserPreference`)

  getPerformancePositionsByBookingId = bookingId =>
    this.request.get(`Booking/${bookingId}/PerformancePositions`)

  savePerformancesPositionTime = (positionId, data) =>
    this.request.put(`Performances/Position/${positionId}`, data)

  getCityMusiciansBySkill = (cityId, skill, data) =>
    this.request.get(`Users/Musician/City/${cityId}/Skill/${skill}`, {
      params: data,
    })

  replaceMusicianPosition = (bookingId, data) =>
    this.request.put(`Booking/${bookingId}/PerformancePositions/Replace`, data)

  assignMusicianToPosition = (bookingId, data) =>
    this.request.put(`Booking/${bookingId}/PerformancePositions/Assign`, data)

  sendDirectOfferToMusician = (positionId, musicianId, force) =>
    this.request.put(
      `Performances/Position/${positionId}/DirectOffer?musicianId=${musicianId}${
        force ? `&force=${force}` : ''
      }`
    )

  // Timelines
  getEventTimeline = eventTimelineId =>
    this.request.get(`EventTimeline/${eventTimelineId}`)

  exportEventTimelineTemplate = fileName =>
    this.request.downloadCsv('/EventTimeLine/ExportTemplate', fileName)

  downloadEventTimelineTemplate = (eventTimelineId, fileName) =>
    this.request.downloadCsv(
      `/EventTimeline/${eventTimelineId}/Export`,
      fileName
    )

  createEventTimeline = data => this.request.post('EventTimelines', data)

  updateEventTimeline = data => this.request.post('EventMoments', data)

  updateEventMoment = (eventMomentId, data) =>
    this.request.put(`EventMoment/${eventMomentId}`, data)

  deleteEventMoment = eventMomentId =>
    this.request.del(`EventMoment/${eventMomentId}`)

  insertEventMoment = (eventTimelineId, data) =>
    this.request.post(`EventTimeLine/${eventTimelineId}/InsertMoments`, data)

  getUploadEventTimelineUrl = (eventTimeLineId, data) =>
    this.request.get(
      `UploadPostProcessRequest/EventTimeLine/${eventTimeLineId}/PresignedUploadPostProcessUrl?objectName=${data.objectName}`
    )

  uploadEventTimelineCsv = (url, file, contentType) =>
    this.request.put(url, file, {
      headers: {
        'Content-Type': contentType,
      },
    })

  getEventTimelineTemplate = () => this.request.get('EventTimelines')

  updateEventTimeline = (eventTimelineId, data) =>
    this.request.put(`EventTimeline/${eventTimelineId}`, data)

  deleteEventTimeline = eventTimelineId =>
    this.request.del(`EventTimeline/${eventTimelineId}`)

  // Songs & Music
  sendKeySong = (gigId, data) =>
    this.request.post(`Gig/${gigId}/KeySongs`, data)

  updateKeySong = (keySongId, data) =>
    this.request.put(`KeySong/${keySongId}`, data)

  deleteKeySong = keySongId => this.request.del(`KeySong/${keySongId}`)

  sendPlaylist = (gigId, data) =>
    this.request.post(`Gig/${gigId}/Playlists`, data)

  updatePlaylist = (playlistId, data) =>
    this.request.put(`Playlist/${playlistId}`, data)

  deletePlaylist = playlistId => this.request.del(`Playlist/${playlistId}`)

  sendTopSong = (gigId, data) =>
    this.request.post(`Gig/${gigId}/Topsongs`, data)

  updateTopSong = (topSongId, data) =>
    this.request.put(`TopSong/${topSongId}`, data)

  deleteTopSong = topSongId => this.request.del(`TopSong/${topSongId}`)

  reorderTopSongs = (gigId, data) =>
    this.request.put(`Gig/${gigId}/Topsongs/Orders`, data)

  uploadSongProductUrl = (url, file, contentType) =>
    this.request.put(url, file, {
      headers: {
        'Content-Type': contentType,
      },
    })

  getUploadSongUrl = (musicId, data) =>
    this.request.get(
      `UploadPostProcessRequest/Music/${musicId}/PresignedUploadPostProcessUrl?objectName=${data.objectName}`
    )

  getGig = gigId => this.request.get(`Gig/${gigId}`)

  getKeySong = keySongId => this.request.get(`KeySong/${keySongId}`)

  getPerformers = data =>
    this.request.get('/Performers/Singers', {
      params: data,
    })

  getGigKeySongs = gigId => this.request.get(`Gig/${gigId}/KeySongs`)

  downloadBookingSheet = (bookingId, fileName) =>
    this.request.downloadPdf(
      `/Booking/${bookingId}/DownloadBookingSheet`,
      fileName,
      { responseType: 'blob' }
    )

  downloadPayListCSV = bookingId =>
    this.request.get(`Booking/${bookingId}/Payments/AirwallexCSV`)

  changeMusicianPaymentStatus = (invoiceId, status) =>
    this.request.put(`Payment/${invoiceId}/Musician/ChangeStatus/${status}`)

  musicianPayNow = bookingId =>
    this.request.post(`Booking/${bookingId}/MusicianPayments/Pay`)

  downloadMusicianInvoice = (invoiceId, fileName) =>
    this.request.get(`Bookings/MusicianInvoice/${invoiceId}/pdf`, fileName, {
      responseType: 'blob',
    })

  completeKeySongs = (id, status) =>
    this.request.put(`Event/${id}/CompleteKeysongs?isComplete=${status}`)

  completeEventTimeline = (id, status) =>
    this.request.put(`Event/${id}/CompleteEventTimeLine?isComplete=${status}`)

  updateAllocateSingers = data =>
    this.request.post(`Bookings/SingerGigAllocation`, data)
}
