import forOwn from 'lodash/forOwn'
import { action, observable, runInAction } from 'mobx'

import BaseAuthStore from '@/flynk.app.web.core.data/stores/Auth/AuthStore'

export default class AuthStore extends BaseAuthStore {
  @observable loadRegistrationInviteLoading = false

  @observable completeRegistrationInviteLoading = false

  @observable isJustCompeleteRegistrationInvite = false

  @observable registrationInfo = {}

  @action.bound
  async loadRegistrationInvite(data) {
    this.loadRegistrationInviteLoading = true

    try {
      const res = await this.rootAPI.userAPI.loadRegistrationInvite(data)

      if (res.payload) {
        runInAction(() => {
          this.registrationInfo = res.payload
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.loadRegistrationInviteLoading = false
    }
  }

  @action.bound
  async completeRegistrationInvite(data) {
    this.completeRegistrationInviteLoading = true

    try {
      await this.rootAPI.userAPI.completeRegistrationInvite(data)
      this.isJustCompeleteRegistrationInvite = true
      await this.login({
        username: data.userName,
        password: data.password,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.completeRegistrationInviteLoading = false
    }
  }

  @action.bound
  logout({ shouldReload = true } = {}) {
    forOwn(this.rootStore, value => {
      if (value && typeof value.reset === 'function') {
        value.reset()
      }
    })

    if (shouldReload) window.location.reload()
  }
}
