import { Provider } from 'mobx-react'
import React from 'react'

import ErrorBoundary from '@/flynk.app.web.core.components/components/Exception/ErrorBoundary'
import { addUnPackToConsole } from '@/flynk.app.web.core.data/helpers/addunpack'
import Router from '@/router'
import rootStore from '@/stores'

import '@/themes/index.less'

addUnPackToConsole()

const App = () => (
  <ErrorBoundary>
    <Provider {...rootStore}>
      <Router />
    </Provider>
  </ErrorBoundary>
)

export default App
