import React from 'react'
import ReactDOM from 'react-dom'

import '@/libs/font-awesome'

import App from './App'
import { migrate } from './migration'
import { init } from './startup'

function startApp() {
  ReactDOM.render(<App />, document.getElementById('root'))
  init()
}

migrate().then(startApp).catch(startApp)
