export const ModalKey = {
  TaskActionsModal: 'task-actions-modal',
  BookingCustomerInfoModal: 'booking-customer-info-modal',
  ProspectCustomerInfoModal: 'prospect-customer-info-modal',
  BookingDetailPackageModal: 'booking-detail-package-modal',
  ProspectDetailPackageModal: 'prospect-detail-package-modal',
  SetReminderModal: 'set-reminder-modal',
}

export const ModalMode = {
  Add: 1,
  Edit: 2,
  View: 3,
}

export const FormMode = {
  Open: 1,
  Display: 2,
}

export const INITIAL_MODAL_DATA = {
  modalMode: null,
  initialValues: null,
}
