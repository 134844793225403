import get from 'lodash/get'

import { BookingStatus } from '../constants'
import { formatBooking } from './booking'
import {
  calculateTotal,
  getFormattedTimeArray,
  getPerformanceTime,
} from './common'

export const checkIsExpiredOffer = bookingStatus =>
  bookingStatus === BookingStatus.Archived.id ||
  bookingStatus === BookingStatus.Cancelled.id

export const getFormattedOffers = offers =>
  offers.reduce((accumulator, offer) => {
    const gigs = get(offer, 'gigOffer', [])
    const rest = { ...offer }
    delete rest.gigOffer

    const gigsFormatted = gigs.map(gig => {
      const gigFormatted = {
        ...gig,
        ...rest,
      }

      const { payments, earning, surcharge } = gigFormatted || {}
      const [payment] = payments || []
      let total = 0

      if (payment) {
        // Future offers and previous offer
        const { items = [], taxAmount } = payment?.invoice || {}

        const originalTotal = calculateTotal(items, 'payable')
        total = payment?.taxRegistered
          ? originalTotal + taxAmount
          : originalTotal
      } else {
        //  Incoming offers
        total = earning || 0 + surcharge || 0
      }

      return {
        ...formatBooking(gigFormatted),
        total,
        timeToShow: getFormattedTimeArray(getPerformanceTime(gigs)),
      }
    })

    const [{ status }] = gigsFormatted
    if (checkIsExpiredOffer(status)) return accumulator

    return [...accumulator, gigsFormatted]
  }, [])

export const checkIsOfferIncoming = offer =>
  !!(offer && offer.length && offer[0].groupOfferId)

export const checkIsMissedOffer = offer =>
  !!(offer && offer.length && offer[0].isCancelled)

export const getSecondsBetweenDates = (startDate, endDate) => {
  const startMilliseconds = new Date(startDate).getTime() // Convert date1 to timestamp in milliseconds
  const endMilliseconds = new Date(endDate).getTime() // Convert date2 to timestamp in milliseconds

  if (Number.isNaN(startMilliseconds) || Number.isNaN(endMilliseconds)) {
    return null
  }

  const differenceInMilliseconds = endMilliseconds - startMilliseconds

  if (differenceInMilliseconds < 0) {
    return 0
  }

  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000) // Convert to seconds

  return differenceInSeconds
}
