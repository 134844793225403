import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import React, { Component } from 'react'

import loadable from '@loadable/component'
import PropTypes from 'prop-types'

import { PrivateRoute as BasePrivateRoute } from '@/flynk.app.web.core.components/router'

const BlankLayout = loadable(() => import('@/layouts/BlankLayout'))

const Exception403 = loadable(
  () => import('@/flynk.app.web.core.components/components/Exception/403')
)

const AccessDeniedPage = () => (
  <BlankLayout>
    <Exception403 />
  </BlankLayout>
)

@inject(stores => ({
  isAccessDenied: stores.profileStore.isAccessDenied,
  isAuthenticated: stores.authStore.isAuthenticated,
}))
@observer
class PrivateRoute extends Component {
  static propTypes = {
    component: MobxPropTypes.objectOrObservableObject.isRequired,
    docTitle: PropTypes.string.isRequired,
    isAccessDenied: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    layout: MobxPropTypes.objectOrObservableObject.isRequired,
    mode: PropTypes.number.isRequired,
  }

  static defaultProps = {
    isAuthenticated: false,
    isAccessDenied: null,
  }

  render() {
    const {
      component,
      docTitle,
      isAccessDenied,
      isAuthenticated,
      layout,
      mode,
      ...rest
    } = this.props

    const denied = isAccessDenied(mode)

    return (
      <BasePrivateRoute
        component={component}
        docTitle={docTitle}
        isAccessDenied={denied}
        isAuthenticated={isAuthenticated}
        layout={layout}
        mode={mode}
        accessDeniedPage={AccessDeniedPage}
        {...rest}
      />
    )
  }
}

export default PrivateRoute
