import React from 'react'

import GlobalPromptProvider from '@/components/GlobalPromptProvider'
import { Router } from '@/flynk.app.web.core.components/router'
import MaintenanceRoutes from '@/router/MaintenanceRoutes'
import Routes from '@/router/Routes'
import { history } from '@/stores'

const router = () => (
  <Router history={history}>
    <GlobalPromptProvider>
      {window.env.REACT_APP_MAINTENANCE_MODE ? (
        <MaintenanceRoutes />
      ) : (
        <Routes />
      )}
    </GlobalPromptProvider>
  </Router>
)

export default router
