import resettableMixin from 'flynk.app.web.core.data/stores/resettableMixin'

import ModalStateStore from './ModalStateStore'

@resettableMixin
class UIStateStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
    this.modalStateStore = new ModalStateStore(args)
  }
}

export default UIStateStore
