import { PropTypes as MobxPropTypes } from 'mobx-react'
import React from 'react'

import PropTypes from 'prop-types'

// import { FaFacebookF, FaYoutube } from 'react-icons/fa'
// import { WebsiteUrl } from '../constants'
import { conditionedClassName } from '../helpers/common'
import TermsAndConditions from './QuoteBuilder/TermsAndConditions'

// const getFooterLinks = (websiteUrl) => {
//   let links

//   switch (websiteUrl) {
//     case WebsiteUrl.CA:
//       links = [
//         { href: '/faq/', text: 'FAQ' },
//         { href: '/red-soda-wedding-band-toronto/', text: 'About' },
//         { href: '/privacy-policy/', text: 'Privacy Policy' },
//         { href: '/popular-wedding-song-list/', text: 'Popular Wedding Song List' },
//         { href: '/testimonials/', text: 'Testimonials' },
//         { href: '/contact-red-soda/', text: 'Contact' },
//       ]
//       break

//     case WebsiteUrl.US:
//       links = [
//         { href: '/faq/', text: 'FAQ' },
//         { href: '/red-soda-wedding-band/', text: 'About' },
//         { href: '/privacy-policy/', text: 'Privacy Policy' },
//         { href: '/popular-wedding-song-list/', text: 'Popular Wedding Song List' },
//         { href: '/testimonials/', text: 'Testimonials' },
//         { href: '/contact-red-soda/', text: 'Contact' },
//       ]
//       break

//     case WebsiteUrl.AU:
//     default:
//       links = [
//         { href: '/faq/', text: 'FAQ' },
//         { href: '/red-soda-wedding-band/', text: 'About' },
//         { href: '/privacy-policy/', text: 'Privacy Policy' },
//         { href: '/popular-wedding-song-list/', text: 'Popular Wedding Song List' },
//         { href: '/testimonials/', text: 'Testimonials' },
//         { href: '/contact-red-soda/', text: 'Contact' },
//         { href: '/recommended-wedding-suppliers/', text: 'Recommended Vendors' },
//       ]
//       break
//   }

//   const host = websiteUrl || WebsiteUrl.AU
//   return links.map(link => ({ ...link, href: `${host}${link.href}` }))
// }

const year = (() => {
  const now = new Date()

  return now.getFullYear()
})()

// const Footer = ({ hasStickyNav, websiteUrl }) => (
const Footer = ({ hasStickyNav, selectedCity }) => (
  <div
    className={conditionedClassName({
      'footer-container': true,
      'has-sticky-nav': hasStickyNav,
    })}
  >
    {/* <div className="footer-social_links">
      <span // temporarily replaced a to spans
        className="footer-social_link"
        href="https://www.facebook.com/RedSodaBand/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookF />
      </span>
      <span
        className="footer-social_link"
        href="https://www.youtube.com/c/RedSoda"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaYoutube />
      </span>
    </div>
    <div className="footer-separator" />
    <ul className="footer-list">
      {getFooterLinks(websiteUrl)
        .map(link => (
          // <a href={link.href}>{link.text}</a>
          <li key={link.href}>
            <span>{link.text}</span>
          </li>
        ))}
    </ul>
    <div className="footer-separator" /> */}
    <div className="footer-container-info">
      <TermsAndConditions selectedCity={selectedCity} />
      <p className="footer-copyright">© Red Soda Band {year}</p>
    </div>
  </div>
)

Footer.propTypes = {
  hasStickyNav: PropTypes.bool.isRequired,
  selectedCity: MobxPropTypes.objectOrObservableObject,
  // websiteUrl: PropTypes.string.isRequired,
}

Footer.defaultProps = {
  selectedCity: {},
}

export default Footer
